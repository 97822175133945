import React, { Component } from 'react';
import MaterialTable from "material-table";
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import { apiService } from '../../services/api.service';

class GoalActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractGoalActionData: [],
            isLoading: true
        }
    }

    GetClientContractGoalAction = () => {
        const data = { CompanyId: this.props.authDetails.CompanyId, ClientTreatmentPlanGoalId: this.props.rowData.ClientTreatmentPlanGoalId }
        apiService.post('GETCONTRACTGOALACTION', data).then((response) => {
            this.setState({ contractGoalActionData: response.ClientContractGoalActions, isLoading: false })
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    componentDidMount() {
        this.GetClientContractGoalAction()
    }

    render() {
        const { contractGoalActionData, isLoading } = this.state
        const headerStyle = {
            fontWeight: 'bold',
            backgroundColor: '#a7afb1'
        }
        const cellStyle = {
            height: '10px',
            backgroundColor: '#f6f6f6'
        }
        return (
            <>
                <div style={{ padding: '10px' }} >
                    <MaterialTable
                        columns={[
                            {
                                title: "Goal Action",
                                field: "ACTION",
                                cellStyle: cellStyle,
                                headerStyle: headerStyle,
                                sorting: false,
                                render: rowData => <> <div data-testid="td-before" className="tdBefore">Goal Action</div>{rowData.ACTION}</>
                            },
                            {
                                title: "Start Date",
                                field: "StartDate",
                                cellStyle: cellStyle,
                                headerStyle: headerStyle,
                                sorting: false,
                                render: rowData => <> <div data-testid="td-before" className="tdBefore">Start Date</div>
                                    <FormatDateTime
                                        date={rowData.StartDate}
                                        format="MM/DD/YYYY"
                                    />
                                </>
                            },
                            {
                                title: "Expected Completion Date",
                                field: "ExpectedCompletionDate",
                                cellStyle: cellStyle,
                                headerStyle: headerStyle,
                                sorting: false,
                                render: rowData => <><div data-testid="td-before" className="tdBefore">Expected Completion Date</div>
                                    <FormatDateTime
                                        date={rowData.ExpectedCompletionDate}
                                        format="MM/DD/YYYY"
                                    />
                                </>
                            },
                            {
                                title: "Actual Completion Date",
                                field: "ActualCompletionDate",
                                cellStyle: cellStyle,
                                headerStyle: headerStyle,
                                sorting: false,
                                render: rowData => <><div data-testid="td-before" className="tdBefore">Actual Completion Date</div>
                                    <FormatDateTime
                                        date={rowData.ActualCompletionDate}
                                        format="MM/DD/YYYY"
                                    />
                                </>
                            }
                        ]}
                        data={contractGoalActionData || []}
                        isLoading={isLoading}
                        options={{
                            search: false,
                            paging: false,
                        }}
                        components={{
                            Toolbar: () => null,
                        }}
                    />
                </div>
            </>
        )
    }
};

export default GoalActions;
