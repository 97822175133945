import React, { Component } from "react";
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class FedClientSanctions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { kioskFedClientSanctions } = this.props;
    return (
      <>
        {kioskFedClientSanctions?.map((sanction, index) => {
            return (
              <div className="card detailPage" key={index}>
                <div className="card-header positiveincident">
                  <div className="row">
                    <div className="col-md-9 col-sm-9">
                      {sanction?.FederalIncidentReportCode}
                    </div>
                    {sanction?.ActionDueDate ?(
                      <div className="col-md-3 col-sm-3">
                        Action Due Date:{" "}
                        <FormatDateTime
                          date={sanction?.ActionDueDate}
                          format="MM/DD/YYYY"
                        />
                      </div>
                    ):<></>}
                  </div>
                </div>
                <div className="card-body">
                  <div className="longtext">
                    <div>
                      {sanction?.FederalIncidentReportCode} -{" "}
                      {sanction?.Description} (
                      <FormatDateTime
                        date={sanction?.ReportingDate}
                        format="MM/DD/YYYY"
                      />
                      ) <div />
                      <div>
                        {sanction?.SanctionType}
                        {sanction?.Hours !== null && (
                          <span>
                            {" "}
                            - {sanction?.HoursCompleted} of {sanction?.Hours}{" "}
                            completed.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      showAlert: bindActionCreators(actions.showAlert, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FedClientSanctions);
