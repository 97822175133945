import React from 'react';
import CurrencyFormatter from '../../../shared/components/functional/CurrencyFormatter';
class Confirmation extends React.Component {
    render() {
        return (
            <>
                {this.props.noTransactionId === true ? 
                <div className="conformation-content">
                    <div className={"unsuccessful-payment"}><i style={{ fontSize: "50px" }} class={"fa fa-times-circle-o"}></i></div>
                    <h5 className={"response-unsuccess"}>{"Payment failed!"} </h5>
                    <h4><CurrencyFormatter currency={this.props.enrollmentDataTotal.PaymentBalance} /></h4>
                    <p>{"Duplicate transactions are not allowed at same time"}</p>
                </div> : <div className="conformation-content">
                    <div className={this.props.paymentResponse.Success ? "confirmation-img" : "unsuccessful-payment"}><i style={{ fontSize: "50px" }} class={this.props.paymentResponse.Success ? "fa fa-check-circle-o" : "fa fa-times-circle-o"}></i></div>
                    <h5 className={this.props.paymentResponse.Success ? 'response-success' : "response-unsuccess"}>{this.props.paymentResponse.Success ? "Success" : "Payment failed!"} </h5>
                    <h4><CurrencyFormatter currency={this.props.enrollmentDataTotal.PaymentBalance} /></h4>
                    <p>{this.props.paymentResponse.Message || "Something went wrong!"}</p>
                    <div className="payment-transaction">
                        {this.props.paymentResponse.Success ? <>Transaction No. {this.props.paymentResponse.transactionid}</> : ""}
                    </div>

                </div>}
            </>
        )
    }

}



export default Confirmation
