const API_URL = process.env.REACT_APP_API_URL;

const API_ENDPOINTS = {
  LOGIN: "/AuthAPI/UserLogin",
  CHANGEUSERPASSWORD: "/AuthAPI/ChangeUserPassword",
  GETHELPFULLINKS: "/TomAPI/GetHelpfulLinks",
  GETRESOURCELIBRARIES: "/TomAPI/GetResourceLibraries",
  DOWNLOADRESOURCELIBRARY: "/TomAPI/DownloadResourceLibrary",
  GETACCOUNTSUMMARIES: "/TomAPI/GetAccountSummaries",
  GETTODAYAGENDAS: "/TomAPI/GetTodaysAgendas",
  GETCLIENTTASKS: "/TomAPI/GetClientTasks",
  GETNOTIFICATIONS: "/TomAPI/GetNotifications",
  GETCLIENTLEAVEWITHSCHEDULES: "/TomAPI/GetClientLeaveWithSchedules",
  GETCLIENTRESIDENTFINANCIALACCOUNTDETAILS:
    "/TomAPI/GetClientResidentFinancialAccountDetails",
  UPDATECLIENTTASK: "/TomAPI/UpdateClientTask",
  UPDATEACKNOWLEDGES: "/TomAPI/UpdateAcknowledges",
  GETGLOBALCODES: "/TomAPI/GetGlobalCodes",
  GETVEHICLEDETAILS: "/TomAPI/GetVehicleDetails",
  GETDRIVERDETAILS: "/TomAPI/GetDriverDetails",
  GETCLIENTRESIDENTFINANCIALACCOUNTS:
    "/TomAPI/GetClientResidentFinancialAccounts",
  GETAGENDAFORDATERANGE: "/TomAPI/GetAgendaForDateRange",
  GETAUDITDETAILS: "/TomAPI/GetAuditDetails",
  VALIDATEAGENDA: "/TomAPI/ValidateAgenda",
  GETALLOWABLELEAVES: "/TomAPI/GetAllowableLeaveDetails",
  GETDESTINATIONS: "/TomAPI/GetDestinations",
  GETLEAVEVALIDATIONCRITERIA: "/TomAPI/GetLeaveValidationCriteria",
  GETSCHEDULEPHASERESTRICTIONCRITERIAS:"/TomAPI/GetSchedulePhaseRestictionCriterias",
  GETVALIDATIONCOPYAGENDA: "/TomAPI/ValidateCopyAgenda",
  GETCLIENTMEETINGDETAIL: "/TomAPI/GetClientMeetingsDetail",
  GETTREATMENTGROUPDETAIL: "/TomAPI/GetTreatmentGroupDetail",
  POSTINSERTMODIFYAGENDA: "/TomAPI/InsertModifyAgenda",
  GETSTAFFLOGIN: "/AuthAPI/StaffLogin",
  GETFACEID: "/TomAPI/GetFaceId",
  GETCLIENTLOCATIONDETAILS: "/TomAPI/GetclientLocationDetails",
  VALIDATEANDINSERTLOCATIONDETAILS: "/TomAPI/ValidateAndInsertLocationDetails",
  INSERTCLIENTIMAGE: "/TomAPI/InsertClientImage",
  GETKIOSKANNOUNCEMENTS: "/TomAPI/GetAnnouncementDetails",
  GETPOSITIVEINCIDENTS: "/TomAPI/GetPositiveIncidents",
  GETKIOSKMESSAGINGGROUPS: "/TomAPI/GetKioskMessagingGroups",
  GETKIOSKMESSAGES: "/TomAPI/GetKioskMessages",
  INSERTKIOSKMESSAGINGCLIENT: "/TomAPI/InsertKioskMessagingClient",
  GETDESTINATIONADDRESS: "/TomAPI/GetDestinationAddress",
  GETCOMMUNITYACCOUNTSUMMARIES: "/TomAPI/GetCommunityAccountSummaries",
  GETCLIENTCOMMUNITYFINANCIALACCOUNTDETAILS: "/TomAPI/GetClientCommunityFinancialAccountDetails",
  GETCLIENTTRANSACTIONDETAILS: "/TomAPI/GetClientTransactionDetails",
  GETCLIENTPAYMENTMETHODS:"/TomAPI/GetClientPaymentMethods",
  DELETEPAYMENTMETHOD:"/TomAPI/DeletePaymentMethod",
  UPDATEPAYMENTMETHOD:"/TomAPI/UpdatePaymentMethod",
  ADDPAYMENTMETHOD: "/TomAPI/AddPaymentMethod",
  GETCLIENTPAYMENTBALANCES:"/TomAPI/GetClientPaymentBalances",
  MAKEPAYMENT: "/TomAPI/MakePayment",
  GETCLIENTCONTRACTS: '/TomAPI/GetClientContracts',
  GETCLIENTCONTRACTGOALS: '/TomAPI/GetClientContractGoals',
  GETCONTRACTGOALACTION: '/TomAPI/GetClientContractGoalActions',
  GETKIOSKCLIENTSURVEYS: '/TomAPI/GetKioskClientSurveys',
  GETSURVEYFIELDDETAILS: '/TomAPI/GetSurveyFieldDetails',
  SUBMITSURVEYFORM: '/TomAPI/InsertSurveyFormData',
  GETCLIENTASSESSMENTS: '/TomAPI/GetKioskClientAssessments',
  INSERTCLIENTASSESSMENT: '/TomAPI/InsertAssessmentsFormData',
  GETKIOSKACTIVESANCTIONS : '/TomAPI/GetActiveSanctions',
};
export const getApiUrl = (key) => {return API_URL + API_ENDPOINTS[key]};
export const APP_URLS = {API_URL};
export const source = window.location.hostname;
export const TIMEOUT_IN_MINUTES = process.env.REACT_APP_TIMEOUT_IN_MINUTES;
export const TIMEOUT_IN_DAYS = process.env.REACT_APP_TIMEOUT_IN_DAYS;
export const NOTIFICATION_INTERVAL_IN_SECONDS = process.env.REACT_APP_NOTIFICATION_INTERVAL_IN_SECONDS;
export const GOOGLE_ANALYTICS_TRACKINGID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID;
export const MERIDIAN_TOKEN = process.env.REACT_APP_MERIDIAN_TOKEN;
  
