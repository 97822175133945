import moment from 'moment';

const FormatDateTime = ({ date, format }) => {
    if (!date)
        return '';

    return (
        moment(date).format(format)
    )
}

export default FormatDateTime;