import React, { Component, Fragment } from "react";
import { apiService } from "../../services/api.service";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Paper, Toolbar, Typography } from "@material-ui/core";
import FedClientSanctions from "./fedClient_Sanctions";
import IRClientSanctions from "./irClient_Sanctions";

const parseSanctions = (sanctions) => {
  return JSON.parse(`[${sanctions ?? ""}]`);
};

class Sanctions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kioskFedClientSanctions: [],
      kioskIRClientSanctions: [],
    };
  }
  GetKioskSanctions = () => {
    const { auth } = this.props;
    apiService
      .post("GETKIOSKACTIVESANCTIONS", {
        ClientId: auth.user.ClientId,
        CompanyId: auth.user.CompanyId,
      })
      .then(
        (response) => {
          const { Sanctions } = response;
          const sanctionData = Sanctions[0];
          const kioskFedClientSanctions = parseSanctions(
            sanctionData?.FedIRClientSanctions
          );
          const kioskIRClientSanctions = parseSanctions(
            sanctionData?.IRClientSanctions
          );
          this.setState({
            kioskFedClientSanctions,
            kioskIRClientSanctions,
          });
        },
        (error) => {
          this.props.actions.showAlert({ message: error, variant: "error" });
        }
      );
  };
  componentDidMount() {
    this.GetKioskSanctions();
  }
  render() {
    const { kioskFedClientSanctions, kioskIRClientSanctions } = this.state;
    return (
      <Fragment>
        <Paper style={{ paddingBottom: 10 }}>
          <Toolbar>
            <Typography variant="h6">Sanctions</Typography>
          </Toolbar>
          <FedClientSanctions
            kioskFedClientSanctions={kioskFedClientSanctions}
          />
          <IRClientSanctions kioskIRClientSanctions={kioskIRClientSanctions} />
        </Paper>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      showAlert: bindActionCreators(actions.showAlert, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sanctions);
