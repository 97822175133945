import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import FormatDateTime from '../../functional/DateTimeFormatter';
import { history } from '../../../../helpers/history';
import CurrencyFormatter from '../../functional/CurrencyFormatter';

const CommunityFinancialTransactionModal = ({ onClose, transaction, showModal, showNavigation }) => {
    return (
        <Modal show={showModal} onHide={onClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Transaction Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{
                transaction &&
                <div className="popup-border modalDetails">
                    {transaction.TransactionType &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Transaction Type:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.TransactionType}</div>
                            </div>
                        </div>
                    }
                    {transaction.Service &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Service:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.Service}</div>
                            </div>
                        </div>
                    }
                    {transaction.PaymentLocation &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Payment Location:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.PaymentLocation}</div>
                            </div>
                        </div>
                    }
                    {transaction.PaymentType &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Payment Type:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.PaymentType}</div>
                            </div>
                        </div>
                    }
                      {transaction.ReceiptNumber &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Receipt Number:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.ReceiptNumber}</div>
                            </div>
                        </div>
                    }

                      {transaction.FeeType &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Fee Type:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">{transaction.FeeType}</div>
                            </div>
                        </div>
                    }

                    {transaction.DueDate &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">DueDate:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02"><FormatDateTime date={transaction.DueDate} format="MM/DD/YYYY" /></div>
                            </div>
                        </div>
                    }
                    {transaction.TransactionAmount &&
                        <div className="col-xs-12 m-b-5">
                            <div className="row">
                                <div className="col-xs-6 col-md-6 col-02">Transaction Amount:</div>
                                <div className="col-xs-6 hideIfEmpty col-md-6 col-02">
                                    <CurrencyFormatter currency={transaction.TransactionAmount} />

                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            </Modal.Body>
            <Modal.Footer>
                {showNavigation && <Button variant="primary" onClick={() => history.push('/CommunityFinancial')}>Go <i className="fa fa-arrow-right"></i></Button>}
                <Button variant="secondary" onClick={onClose}> Close </Button>
            </Modal.Footer>
        </Modal >
    )
}
export default CommunityFinancialTransactionModal;