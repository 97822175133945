import React, { Component } from 'react'
import { history } from '../../helpers/history'
import { Button, Modal } from 'react-bootstrap';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import { apiService } from '../../services/api.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bindActionCreators } from 'redux';
import * as actions from "../../store/actions";
import SurveyField from './dynamicFields';
import { connect } from 'react-redux';

class AddSurvey extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldDetails: [],
            formFields: { KioskClientSurveysAssignedId: '' },
            isLoading: true,
            globalCodeOptions: [],
            isDisableOptions: true,
            showModal: false,
        }
    }

    GetSurveyFieldDetails = () => {
        const { auth, rowData, actions } = this.props.location.state
        const data = { CompanyId: auth.user.CompanyId, SurveyTemplateId: rowData.SurveyTemplateId, KioskClientSurveysAssignedId: rowData.KioskClientSurveysAssignedId }
        apiService.post('GETSURVEYFIELDDETAILS', data).then((response) => {
            this.setState({ fieldDetails: response.SurveyFieldDetails, formFields: { KioskClientSurveysAssignedId: rowData.KioskClientSurveysAssignedId }, isLoading: false })
            this.fetchGlobalCodeOptions()
        }, error => {
            this.setState({ isLoading: false });
            actions.showAlert({ message: error, variant: 'error' })
        });
    }

    fetchGlobalCodeOptions = () => {
        const globalCodeTypes = this.state.fieldDetails.filter(field => field.DataTypeText === 'GlobalCode' || field.DataTypeText === 'ListBox');
        const apiCalls = globalCodeTypes.map(type => {
            var requestdata = { CategoryName: type.GlobalCodeCategory };
            return apiService.post('GETGLOBALCODES', requestdata);
        });
        Promise.all(apiCalls)
            .then(responses => {
                const newGlobalCodeOptions = responses.reduce((options, response) => {
                    const uniqueOptions = response.GlobalCode.filter(item => {
                        return !options.some(existingOption => existingOption.CodeName === item.CodeName);
                    });
                    return options.concat(uniqueOptions);
                }, []);
                this.setState({ globalCodeOptions: newGlobalCodeOptions, isDisableOptions: false });
            })
            .catch(error => {
                this.setState({ isDisableOptions: false });
            });
    };

    handleSubmit = () => {
        const { formFields } = this.state;
        const { auth } = this.props.location.state;
        const updatedFormFields = { ...formFields };
        Object.keys(updatedFormFields).forEach(fieldName => {
            if (fieldName.startsWith('ColumnListBox')) {
                const selectedValues = updatedFormFields[fieldName] || [];
                updatedFormFields[fieldName] = selectedValues.join(', ');
            }
            if (fieldName.startsWith('ColumnCheckBox')) {
                updatedFormFields[fieldName] = updatedFormFields[fieldName] ? 'Y' : 'N';
            }
            if (fieldName.startsWith('ColumnDatetime') && updatedFormFields[fieldName]) {
                const timeFieldName = fieldName.replace('ColumnDatetime', 'Columntime');
                const timeValue = updatedFormFields[timeFieldName] instanceof Date
                    ? updatedFormFields[timeFieldName].toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
                    : '' || '00:00';
                updatedFormFields[fieldName] = `${updatedFormFields[fieldName]} ${timeValue}`;
                delete updatedFormFields[timeFieldName];
            }
        });

        const data = { Json: JSON.stringify(updatedFormFields), ModifiedBy: auth.user.UserName, ClientId: auth.user.ClientId };
        apiService.post('SUBMITSURVEYFORM', data).then(response => {
            if (response.Success) {
                this.props.actions.showAlert({ message: response.Message, variant: 'success' });
            }
            history.push('/Surveys');
            this.setState({ loading: false, formFields: {} });
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
            this.setState({ loading: false, formFields: {} });
        });
    };

    handleDateChange = (date, fieldName) => {
        const { formFields } = this.state;
        if (date === null || date === '') {
            formFields[fieldName] = '';
        } else if (date instanceof Date && !isNaN(date)) {
            const formattedDate = date.toISOString().split('T')[0];
            formFields[fieldName] = formattedDate;
        }
        this.setState({ formFields });
    };

    handleTimeChange = (time, fieldName) => {
        const { formFields } = this.state;
        const formattedTime = time
        formFields[fieldName] = formattedTime;
        this.setState({ formFields });
    };

    handleChange = (e) => {
        const { formFields } = this.state;
        const fieldName = e.target.name;
        let fieldValue = e.target.type === 'checkbox' ? (e.target.checked ? true : false) : e.target.value;
        formFields[fieldName] = fieldValue;
        this.setState({ formFields });
    };

    handleListBox = (e) => {
        const { formFields } = this.state;
        const fieldName = e.target.name;
        const selectedOptions = Array.from(e.target.selectedOptions);
        const fieldValue = selectedOptions.map(option => parseInt(option.value));
        formFields[fieldName] = fieldValue;
        this.setState({ formFields });
    };

    handleCancelSurvey = () => {
        const { formFields } = this.state;
        if (Object.keys(formFields).length > 1)
            this.toggleModal();
        else
            history.push('/Surveys')
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    componentDidMount() {
        this.GetSurveyFieldDetails()
    }

    render() {
        const { fieldDetails, isLoading, formFields } = this.state
        return (
            <>
                <div className="backToAccount">
                    <button type="button" className="btn btn-link" onClick={this.handleCancelSurvey}><i className="fa fa-arrow-left"></i> Back</button>
                </div>
                <div className='survey-form' >
                    {!isLoading ?
                        <ValidatorForm
                            id={"dynamicForm"}
                            onSubmit={this.handleSubmit}
                        >
                            <div className="row">
                                {fieldDetails.map((input, index) => {
                                    const isFullWidth = input.DataTypeText === 'Headers' || input.DataTypeText === 'HorizontalLine' || input.DataTypeText === 'StraightText'
                                    return (
                                        <div key={index} className={isFullWidth ? 'col-md-12' : 'col-md-6'}>
                                            <SurveyField
                                                key={input.OrderNumber}
                                                type={input}
                                                formFields={formFields}
                                                isDisableOptions={this.state.isDisableOptions}
                                                globalCodeOptions={this.state.globalCodeOptions}
                                                handleChange={this.handleChange}
                                                handleDateChange={this.handleDateChange}
                                                handleTimeChange={this.handleTimeChange}
                                                handleListBox={this.handleListBox}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </ValidatorForm>
                        : <div className="survey_loader"><div className="loader"><CircularProgress /></div></div>}
                    <div className='surveyButtons' >
                        <Button variant="secondary" onClick={this.handleCancelSurvey}>
                            Cancel
                        </Button>
                        <button
                            type="submit"
                            form="dynamicForm"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={this.submitForm}
                        >
                            Submit
                        </button>
                    </div>
                </div>

                <Modal
                    show={this.state.showModal}
                    onHide={this.toggleModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "90px" }}>
                        Are you sure you want to cancel this survey?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleModal}>
                            No
                        </Button>
                        <Button onClick={() => { history.push('/Surveys') }} >Yes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSurvey);