import React from 'react';
import CurrencyFormatter from '../../../shared/components/functional/CurrencyFormatter';
class PaymentSummary extends React.Component {
    render() {
        return (
            <>
                <div className="payment-content">
                    <div className='payment-amount-table-container' >
                        <table className='payment-amount-table'>
                            <thead>
                                <th>Account</th>
                                <th>Payment</th>
                            </thead>
                            {
                                this.props.paymentSummaryData.map((enrollmentDetail) => {
                                    return <>
                                        <tr>
                                            <td>{enrollmentDetail.Description}</td>
                                            <td>{enrollmentDetail.Services.length === 0 && <CurrencyFormatter currency={enrollmentDetail.PaymentBalance} />}</td>
                                        </tr>
                                        {enrollmentDetail.Services?.map((serviceDetail) => {
                                            return <tr className='service-row'>
                                                <td>{serviceDetail.Service}</td>
                                                <td style={{ position: "relative", right: "20px" }} ><CurrencyFormatter currency={serviceDetail.PaymentBalance} /></td>
                                            </tr>
                                        })}
                                    </>
                                })
                            }
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td> <CurrencyFormatter currency={this.props.enrollmentDataTotal.PaymentBalance} /> </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="payment-data">
                        <div className='payment-method' >
                            <label style={{ fontWeight: "bold", margin: "7px" }}>Payment method:</label>
                            <div class="added-cards">
                                <span className='card-name' >{this.props?.selectedCard?.CardType || this.props.paymentCardDetail?.card?.type}</span>
                                <div>
                                    <span className='card-number' >*** *** *** </span> <span>{this.props?.selectedCard?.CardNumber || this.props?.paymentCardDetail?.card?.number?.substr(-4)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='review-text'>Please review the above information carefully. Your payment will be processed when you tap the Submit Payment.</div>
                    </div>
                </div>
            </>
        )
    }

}



export default PaymentSummary
