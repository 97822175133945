import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import GoalActions from './contract_goal_actions';

class ContractGoals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractData: [],
            hitContractGoal: false,
            isLoading: true,
        }
    }

    GetClientContractGoals = () => {
        const data = { ClientId: this.props.authDetails.ClientId, CompanyId: this.props.authDetails.CompanyId, ClientTreatmentPlanId: this.props.contractId }
        apiService.post('GETCLIENTCONTRACTGOALS', data).then((response) => {
            this.setState({ contractData: response.ClientContractGoals, isLoading: false })
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    componentDidMount() {
        this.GetClientContractGoals()
    }

    render() {
        const { contractData, isLoading } = this.state
        return (
            <>
                <div className="backToAccount">
                    <button type="button" className="btn btn-link" onClick={this.props.closeContractDetail}><i className="fa fa-arrow-left"></i> Back</button>
                </div>
                <MaterialTable
                    detailPanel={[{
                        tooltip: 'Goal actions',
                        render: rowData => {
                            return (
                                <GoalActions authDetails={this.props.authDetails} rowData={rowData} />
                            )
                        }
                    },
                    ]}

                    columns={
                        [
                            {
                                title: "Action",
                                field: "Action",
                                sorting: false,
                            },
                            {
                                title: "Risk Factor",
                                field: "RiskFactor",
                                sorting: false,
                                render: rowData => <> <div data-testid="td-before" className="tdBefore">Risk Factors</div>{rowData.RiskFactor}</>
                            },
                            {
                                title: "Goal",
                                field: "Goal",
                                sorting: false,
                                render: rowData => <><div data-testid="td-before" className="tdBefore">Goal</div>{rowData.Goal}</>
                            },
                        ]}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [5, 10, 25, 50, 100],
                        toolbar: true,
                        paging: true,
                        paginationType: "normal",
                        view: true,
                        headerStyle: {
                            backgroundColor: '#4f656c',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        minBodyHeight: "300px",
                        draggable: false
                    }}
                    isLoading={isLoading}
                    data={contractData || []}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "There are no goals to display"
                        },
                        pagination: {
                            labelRowsSelect: "rows per page"
                        }
                    }}
                    title="Contract Details"
                />
            </>
        )
    }
}

export default (ContractGoals)