import React, { Component } from 'react';

class NoMessage extends Component {
    render() {
        return (
            <div className="no-message-yet">
                 <i className="fa fa-weixin"></i>
                 <h3>{this.props.message}</h3>
            </div>
        );
    }
}

export default NoMessage;
