import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import NoMessage from './nomessage';

class MessagesBody extends Component {
    renderView = ({ style, ...props }) => {
        const viewStyle = {
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 25
        };
        return (
            <div
                className="box"
                style={{ ...style, ...viewStyle }}
                {...props} />
        );
    }
    componentDidUpdate() {
        const { kioskMessagesList, loadingMessagesList } = this.props;
        !loadingMessagesList && kioskMessagesList && kioskMessagesList.length > 0 && this.scrollComponent.scrollToBottom();
    }
    render() {
        const { kioskMessagesList, loadingMessagesList } = this.props;

        return (
            <div className="mbl-messages c-overflow">
                <Scrollbars className="scrollBar" renderView={this.renderView} autoHide ref={c => { this.scrollComponent = c }} >
                    {!loadingMessagesList && <>
                        {kioskMessagesList && kioskMessagesList.length > 0 && kioskMessagesList.map((item, key) => {
                            return (
                                <div className={`mblm-item ${item.Position === "Right" ? 'mblm-item-right' : 'mblm-item-left'}`}>
                                    <div>{item.Message}</div>
                                    <small>{`${moment(item.CreatedDate).format("MM/DD/YYYY hh:mm a")}  ${item.SenderName===""? '':('('+item.SenderName+')')}`}</small>
                                </div>
                            )
                        })
                        }
                        {kioskMessagesList && kioskMessagesList.length === 0 && <NoMessage message="You currently have no messages." />}
                    </>
                    }
                    {loadingMessagesList && (
                        <div className="loaderDiv">
                            <div className="loader">
                                <CircularProgress />
                            </div>
                        </div>
                    )}
                </Scrollbars>
            </div>
        );
    }
}

export default MessagesBody;
