
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { history } from '../../helpers/history'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "../../store/actions";
import FormatDateTime from '../../shared/components/functional/DateTimeFormatter';
import { apiService } from '../../services/api.service';

class AssessmentsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AssessmentData: [],
            isLoading: true
        }
    }

    GetKioskClientSurveys = () => {
        const { auth } = this.props;
        const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
        apiService.post('GETCLIENTASSESSMENTS', data).then((response) => {
            this.setState({ AssessmentData: response.kioskClientAssessments, isLoading: false })
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    componentDidMount() {
        this.GetKioskClientSurveys()
    }

    render() {
        const { isLoading, AssessmentData } = this.state
        return (
            <>
                <MaterialTable
                    columns={[
                        {
                            title: "Select",
                            field: "Select",
                            sorting: true,
                            render: rowData =>
                                <>
                                    <div data-testid="td-before" className="tdBefore">Select</div>
                                    <i className="fa fa-circle-o" aria-hidden="true" onClick={() => {
                                        history.push({
                                            pathname: '/Assessments/Details',
                                            state: { rowData: rowData, }
                                        })
                                    }} ></i>
                                </>
                        },
                        {
                            title: "Assessment Type",
                            field: "AssessmentType",
                            sorting: true,
                            render: rowData => {
                                return (
                                    <> <div data-testid="td-before" className="tdBefore">Assessment Type</div>{rowData.AssessmentType}</>)
                            }
                        },
                        {
                            title: "Assessment Date",
                            field: "AssignedDate",
                            sorting: true,
                            render: rowData => {
                                return (
                                    <> <div data-testid="td-before" className="tdBefore">Assessment Date</div>
                                        <FormatDateTime
                                            date={rowData.AssignedDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </>)
                            }
                        },
                    ]}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [5, 10, 25, 50, 100],
                        toolbar: true,
                        paging: true,
                        paginationType: "normal",
                        view: true,
                        headerStyle: {
                            backgroundColor: '#4f656c',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        minBodyHeight: "300px",
                        draggable: false
                    }}
                    isLoading={isLoading}
                    data={AssessmentData || []}

                    localization={{
                        body: {
                            emptyDataSourceMessage: "There are no Assessments to display"
                        },
                        pagination: {
                            labelRowsSelect: "rows per page"
                        }
                    }}
                    title="Assessments"
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentsGrid);


