import React from 'react';
import { Checkbox } from '@material-ui/core';
import TextValidator from '../../shared/components/ui/form/text-validator';
import SelectValidator from '../../shared/components/ui/form/select-validator';
import ListBox from '../../shared/components/ui/form/list-box';
import CommonDatePicker from '../../shared/components/ui/form/date-picker';
import CommonTimePicker from '../../shared/components/ui/form/time-picker';

function isRequiredLabel(type) {
    return (<> <span className="form-label">{type.ColumnName} {type.Required === 'Y' && <span style={{ color: '#ff0000' }}>*</span>} </span> </>);
}

function SurveyField({ type, formFields, isDisableOptions, handleDateChange, handleTimeChange, globalCodeOptions, handleChange, handleListBox }) {
    const isRequired = type.Required === 'Y' ? ['required'] : []
    const fieldName = type.FieldName

    switch (type.DataTypeText) {
        case 'Headers':
            return <>
                <div>
                    <h3 style={{ fontWeight: 'bold', paddingTop: '20px' }}  >{type.ColumnName}</h3>
                </div>
            </>;
        case 'HorizontalLine':
            return <div> <hr></hr> </div>;
        case 'StraightText':
            return <h5 >
                {type.ColumnName}
            </h5>
        case 'Varchar':
            return <div className='survey-inputs'>
                {isRequiredLabel(type)}
                <TextValidator
                    validators={isRequired}
                    errorMessages={['This field is required.']}
                    type="text"
                    className="form-control"
                    placeholder={type.ColumnName}
                    name={fieldName}
                    onChange={handleChange}
                    value={formFields[fieldName] || ''}
                />
            </div>
        case 'CheckBox':
            return <div className='survey-inputs' >
                {isRequiredLabel(type)} <Checkbox name={fieldName} onChange={handleChange} checked={formFields[fieldName] || false} ></Checkbox></div>
        case 'Date':
            const showTime = type.ShowTime === 'Y'
            return <div className="row">
                <div className="col-md-6" >
                    {isRequiredLabel(type)}
                    <CommonDatePicker
                        className="form-control"
                        name={fieldName}
                        value={formFields[fieldName] || ''}
                        onChange={handleDateChange}
                        validators={isRequired}
                        errorMessages={['This field is required.']}
                    />
                </div>
                <div className="col-md-6" >
                    {showTime && <><span className="form-label">Time</span>
                        <CommonTimePicker
                            className="form-control"
                            name={`Columntime${fieldName.slice(-1)}`}
                            value={formFields[`Columntime${fieldName.slice(-1)}`] || ""}
                            onChange={handleTimeChange}
                            validators={isRequired}
                            errorMessages={['This field is required.']}
                        />
                    </>
                    }
                </div >
            </div >

        case 'GlobalCode':
            return <div className='survey-inputs' >
                {isRequiredLabel(type)}

                <SelectValidator
                    validators={isRequired}
                    errorMessages={['This field is required.']}
                    name={fieldName}
                    onChange={handleChange}
                    value={formFields[fieldName] || ''}
                    fullWidth
                >
                    {isDisableOptions ? <option value='' disabled >Loading...</option> : <option value="" >Select option</option>}
                    {globalCodeOptions && globalCodeOptions.map((item) => {
                        if (type?.GlobalCodeCategory === item?.CategoryName) {
                            return (
                                <option key={item.Id} value={item.Id}>
                                    {item.CodeName}
                                </option>
                            )
                        }
                        return null
                    })
                    }
                </SelectValidator>

            </div>
        case 'Integer':
            return <div className='survey-inputs' >
                {isRequiredLabel(type)}
                <TextValidator
                    validators={isRequired}
                    errorMessages={['This field is required.']}
                    type="number"
                    className="form-control"
                    placeholder={type.ColumnName}
                    name={fieldName}
                    onChange={handleChange}
                    value={formFields[fieldName] || ''}
                />
            </div>
        case 'Money':
            return <div className='survey-inputs' >
                {isRequiredLabel(type)}
                <TextValidator
                    validators={isRequired}
                    errorMessages={['This field is required.']}
                    type="number"
                    className="form-control"
                    placeholder={type.ColumnName}
                    name={fieldName}
                    onChange={handleChange}
                    value={formFields[fieldName] || ''}
                />
            </div>
        case 'TextArea':
        case 'Text':
            return <div className='survey-inputs' >
                {isRequiredLabel(type)}
                <TextValidator
                    validators={isRequired}
                    errorMessages={['This field is required.']}
                    inputType="textarea"
                    className="form-control"
                    placeholder={type.ColumnName}
                    name={fieldName}
                    onChange={handleChange}
                    value={formFields[fieldName] || ''}
                />
            </div>;
        case 'ListBox':
            return <div className='survey-inputs'> {isRequiredLabel(type)}
                <ListBox
                    validators={isRequired}
                    className="form-control"
                    errorMessages={['This field is required.']}
                    name={fieldName}
                    onChange={handleListBox}
                    value={formFields[fieldName] || ''}
                    fullWidth
                >
                    {isDisableOptions && <option value='' disabled >Loading...</option>}
                    {globalCodeOptions && globalCodeOptions.map((item) => {
                        if (type?.GlobalCodeCategory === item?.CategoryName) {
                            return (
                                <option key={item.Id} value={item.Id}>
                                    {item.CodeName}
                                </option>
                            )
                        }
                        return null
                    })
                    }
                </ListBox>
            </div>;
        default:
            return <></>;
    }
}

export default SurveyField;