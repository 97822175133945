import React from "react";
import { Modal, Button } from "react-bootstrap";
import FormatDateTime from "../../functional/DateTimeFormatter";
import CurrencyFormatter from "../../functional/CurrencyFormatter";


const PaymentsModal = ({ onClose, showModal, paymentDetail }) => {
  return (
    <Modal
      show={showModal}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Payment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="popup-border modalDetails">
          <div className="col-xs-12 m-b-5">
            <div className="row">
              <div className="col-xs-6 col-md-6 col-02">Total Amount:</div>
              <div className="col-xs-6 hideIfEmpty col-md-6 col-02">
                <CurrencyFormatter currency={paymentDetail?.TransactionAmount} />
              </div>
            </div>
          </div>
          <div className="col-xs-12 m-b-5">
            <div className="row">
              <div className="col-xs-6 col-md-6 col-02">Status:</div>
              <div className="col-xs-6 hideIfEmpty col-md-6 col-02">
                {paymentDetail?.TransactionResponse?.toUpperCase()}
              </div>
            </div>
          </div>
          <div className="col-xs-12 m-b-5">
            <div className="row">
              <div className="col-xs-6 col-md-6 col-02">Date/Time:</div>
              <div className="col-xs-6 hideIfEmpty col-md-6 col-02">

                <FormatDateTime date={paymentDetail?.TransactionDate} format="MM/DD/YYYY hh:mm A" />
              </div>
            </div>
          </div>


          <div className="col-xs-12 m-b-5">
            <div className="row">
              <div className="col-xs-6 col-md-6 col-02">Meridian Transaction Id:</div>
              <div className="col-xs-6 hideIfEmpty col-md-6 col-02">
                {paymentDetail?.MeridianTransactionId}
              </div>
            </div>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PaymentsModal;
