import React, { Component, Fragment } from "react";
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import { apiService } from "../../services/api.service";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Paper, Toolbar, Typography } from "@material-ui/core";

class PositiveIncidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kioskAnnouncements: []
    };
  }
  GetPositiveIncidents = () => {
    const { auth } = this.props;

    apiService
      .post("GETPOSITIVEINCIDENTS", {
        Id: -1,
        ClientId: auth.user.ClientId,
        Days: -1,
      })
      .then(
        (response) => {
          if (response.Success && response.positiveIncidentsList) {
            this.setState({
              kioskPositiveIncidents: response.positiveIncidentsList,
            });
          }
        },
        (error) => {
          this.props.actions.showAlert({ message: error, variant: "error" });
        }
      );
  };

  componentDidMount() {
    this.GetPositiveIncidents();
  }
  render() {
    const { kioskPositiveIncidents } = this.state;
    return (
      <Fragment>
        <Paper style={{paddingBottom:10}}>
          <Toolbar>
            <Typography variant="h6">Positive Incidents</Typography>
          </Toolbar>
          { 
            kioskPositiveIncidents && kioskPositiveIncidents.map((pi) => 
              <div className="card detailPage" key={pi.Id}>
                <div className="card-header positiveincident">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <FormatDateTime
                        date={pi.IncidentDateTime}
                        format="MM/DD/YYYY"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      { pi.IncidentType }
                    </div>
                    <div className="col-md-3 col-sm-6">
                      { pi.ReportingStaff }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="longtext">
                    { pi.IncidentDescription }
                  </div>
                </div>
              </div>
            )
          }
        </Paper>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      showAlert: bindActionCreators(actions.showAlert, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PositiveIncidents);
