import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PaymentsModal from "../../shared/components/ui/modal/payments-modal";
import PaymentMethodModal from "../../shared/components/ui/modal/payment-method-modal";
import UpdatePaymentMethodModal from "../../shared/components/ui/modal/update-payment-method-modal";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import PaymentGrid from "./payment_grid";
import CardGrid from "./card_grid";
import { apiService } from "../../services/api.service";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      classes: makeStyles((theme) => ({
        root: {
          flexGrow: 1,
          backgroundColor: theme.palette.background.paper,
        },
      })),
      isLoading: true,
      showModal: false,
      showPaymentMethodModal: false,
      showUpdatePaymentMethodModal: false,
      TransactionDetails: [],
      modalType: '',
      PaymentMethods: [],
      VaultId: []
    };

  }

  GetTransactionDetails = () => {
    const { auth } = this.props;
    const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
    apiService.post('GETCLIENTTRANSACTIONDETAILS', data).then((response) => {
      this.setState({ TransactionDetails: response.ClientTransactionDetails, isLoading: false })
    }, error => {
      this.setState({ isLoading: false });
      this.props.actions.showAlert({ message: error, variant: 'error' });
    });
  }

  GetPaymentMethods = () => {
    this.setState({ isLoading: true })
    const { auth } = this.props;
    const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
    apiService.post('GETCLIENTPAYMENTMETHODS', data).then(response => {
      this.setState({ PaymentMethods: response.ClientPaymentMethods, isLoading: false })
    }, error => {
      this.setState({ isLoading: false });
      this.props.actions.showAlert({ message: error, variant: 'error' });
    });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  openPaymentModal = (e, event) => {
    this.setState({ showModal: true, modelDetail: event });
  };
  close = () => {
    this.setState({ showModal: false, modelDetail: {} });
  };

  openPaymentMethodModal = (e) => {
    this.setState({ showPaymentMethodModal: true, })
  };
  closePaymentMethodModal = () => {
    this.setState({ showPaymentMethodModal: false })

  };
  openUpdatePaymentMethodModal = (e, item) => {
    this.setState({ showUpdatePaymentMethodModal: true, cardDetail: item })
  };
  closeUpdatePaymentMethodModal = () => {
    this.setState({ showUpdatePaymentMethodModal: false })

  };

  componentDidMount() {
    this.GetTransactionDetails()
    this.GetPaymentMethods()
  };

  render() {

    const {
      value,
      classes,
      isLoading,
      showModal,
      modelDetail,
      showPaymentMethodModal,
      showUpdatePaymentMethodModal,
      TransactionDetails,
      PaymentMethods,
      cardDetail
    } = this.state;



    return (

      <div className={classes.root}>
        <Fragment>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleChange}>
              <Tab label="Payments" {...a11yProps(0)} />
              <Tab label="Cards" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <PaymentGrid
              openPaymentModal={this.openPaymentModal}
              isLoading={isLoading}
              TransactionDetails={TransactionDetails}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardGrid
              openConfirmationModal={this.openConfirmationModal}
              isLoading={isLoading}
              openPaymentMethodModal={this.openPaymentMethodModal}
              openUpdatePaymentMethodModal={this.openUpdatePaymentMethodModal}
              GetPaymentMethods={this.GetPaymentMethods}
              PaymentMethods={PaymentMethods}
            />
          </TabPanel>
        </Fragment>
        <PaymentsModal
          onClose={this.close}
          showModal={showModal}
          paymentDetail={modelDetail}
        />
        <UpdatePaymentMethodModal showUpdateModal={showUpdatePaymentMethodModal} onClose={this.closeUpdatePaymentMethodModal} GetPaymentMethods={this.GetPaymentMethods} cardDetail={cardDetail} />
        <PaymentMethodModal showModal={showPaymentMethodModal} onClose={this.closePaymentMethodModal}  GetPaymentMethods={this.GetPaymentMethods} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      showAlert: bindActionCreators(actions.showAlert, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
