import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import * as actions from "../../store/actions";
import { apiService } from '../../services/api.service';
import ContractGoals from './contract_goal_details';
import FormatDateTime from '../../shared/components/functional/DateTimeFormatter';
class Contract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractData: [],
            authDetails: {},
            contractId: '',
            isLoading: true,
            showContractDetails: false,
        }
    }

    handleOpenContractDetails = (e, rowData) => {
        this.setState({ showContractDetails: true, contractId: rowData.ClientTreatmentPlanId })
    }

    closeContractDetail = () => {
        this.setState({ showContractDetails: false })
    }

    GetClientContracts = () => {
        const { auth } = this.props;
        const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
        apiService.post('GETCLIENTCONTRACTS', data).then((response) => {
            this.setState({ contractData: response.ClientContracts, authDetails: auth.user, isLoading: false })
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    componentDidMount() {
        this.GetClientContracts()
    }

    render() {
        const { contractData, showContractDetails, contractId, authDetails, isLoading } = this.state;
        return (
            <>
                {showContractDetails ? <ContractGoals closeContractDetail={this.closeContractDetail} authDetails={authDetails} contractId={contractId} /> :
                    <>
                        <MaterialTable
                            columns={[
                                {
                                    title: "Select",
                                    field: "Description",
                                    sorting: false,
                                    render: rowData =>
                                        <>
                                            <div data-testid="td-before" className="tdBefore">Select</div>
                                            <i className="fa fa-circle-o" aria-hidden="true" onClick={(e) => { this.handleOpenContractDetails(e, rowData) }} ></i>
                                        </>

                                },
                                {
                                    title: "Contract Name",
                                    field: "TreatmentPlanName",
                                    sorting: false,
                                    render: rowData => {
                                        return (
                                            <> <div data-testid="td-before" className="tdBefore">Contract Name</div>{rowData.TreatmentPlanName}</>)
                                    }
                                },
                                {
                                    title: "Contract Date",
                                    field: "TreatmentPlanDate",
                                    sorting: false,
                                    render: rowData => <><div data-testid="td-before" className="tdBefore">Contract Date</div>
                                        <FormatDateTime
                                            date={rowData.TreatmentPlanDate}
                                            format="MM/DD/YYYY"
                                        />
                                    </>
                                },
                            ]}
                            options={{
                                pageSize: 25,
                                pageSizeOptions: [5, 10, 25, 50, 100],
                                paging: true,
                                paginationType: "normal",
                                view: true,
                                headerStyle: {
                                    backgroundColor: '#4f656c',
                                    color: '#FFF'
                                },
                                actionsColumnIndex: -1,
                                minBodyHeight: "300px",
                                draggable: false
                            }}
                            isLoading={isLoading}
                            data={contractData || []}

                            localization={{
                                body: {
                                    emptyDataSourceMessage: "There are no contracts to display"
                                },
                                pagination: {
                                    labelRowsSelect: "rows per page"
                                }
                            }}
                            title="Contract"
                        /></>}
            </>
        )
    }

};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
