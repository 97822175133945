import React, { Fragment } from "react";
import MaterialTable from "material-table";
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import CurrencyFormatter from "../../shared/components/functional/CurrencyFormatter";

const PaymentGrid = ({
  isLoading,
  openPaymentModal,
  TransactionDetails
}) => {

  return (
    <Fragment>
      <MaterialTable
        columns={[
          {
            title: "Date/Time",
            field: "TransactionDate",
            sorting: false,
            searchable: false,
            render: (rowData) => (
              <Fragment>
                <div data-testid="td-before" className="tdBefore">
                  Date/Time
                </div>
                <FormatDateTime date={rowData.TransactionDate} format="MM/DD/YYYY hh:mm A" />
              </Fragment>
            ),
          },
          {
            title: "Total Amount",
            field: "TransactionAmount",
            searchable: true,
            render: (rowData) => (
              <Fragment>
                <div data-testid="td-before" className="tdBefore">
                  Total Amount
                </div>
                <CurrencyFormatter currency={rowData.TransactionAmount} />
              </Fragment>
            ),
          },
          {
            title: "Status",
            field: "TransactionResponse",
            searchable:true,
            render: (rowData) => (
              <Fragment>
                <div data-testid="td-before" className="tdBefore">Status</div>
                {rowData.TransactionResponse?.toUpperCase()}
              </Fragment>
            ),
          },
          {
            title: "Action",
            field: "Action",
            searchable: false,
            render: (rowData) => (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={(e) => openPaymentModal(e, rowData)}
                >
                  <i className="fa fa-info" aria-hidden="true" />
                </button>
              </Fragment>
            ),
          },
        ]}
        options={{
          pageSize: 25,
          pageSizeOptions: [5, 10, 25, 50, 100],
          toolbar: true,
          paging: true,
          paginationType: "normal",
          view: true,
          headerStyle: {
            backgroundColor: "#4f656c",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
          minBodyHeight: "300px",
          draggable: false,
        }}
        isLoading={isLoading}
        data={TransactionDetails}
        localization={{
          body: {
            emptyDataSourceMessage: "There are no payments to display",
          },
          pagination: {
            labelRowsSelect: "rows per page",
          },
        }}
        title="Payments"
      />
    </Fragment>
  );
}

export default PaymentGrid;
