import React from 'react';
import { Modal, Button } from "react-bootstrap";
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import TextValidator from '../form/text-validator';
import { CircularProgress } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import { apiService } from '../../../../services/api.service';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
class UpdatePaymentMethodModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: {
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                cardnumber: '',
                cardtype: '',
                cvv: null,
                expiryDate: ''
            },
            isInvalidState: false,
            isInvalidExpiration: false,
            isSubmitting: false,
            isLoading: false,
            token: [],
            states: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    };
    getGlobalCodes = () => {
        this.setState({ loading: true });
        var requestdata = { CategoryName: 'state', RecordDeleted: 'N' };
        apiService.post('GETGLOBALCODES', requestdata).then(response => {
          this.setState({
            states: response.GlobalCode
          })
        }, error => {
          this.props.actions.showAlert({ message: error, variant: 'error' });
        });
      }

    handleSubmit(event) {
        if (!this.state.isInvalidState && !this.state.isInvalidExpiration) {
            this.setState({ isLoading: true });
            const paymentMethod = this.state.paymentMethod
            const { auth } = this.props
            const data = {
                ClientId: auth.user.ClientId,
                CustomerVaultId: this.props.cardDetail.CustomerVaultId,
                first_name: paymentMethod.first_name,
                last_name: paymentMethod.last_name,
                city: paymentMethod.city,
                state: paymentMethod.state,
                zip: paymentMethod.zip,
                address: paymentMethod.address,
                customer_vault: "update_customer",
                ccexp: `${paymentMethod.expiryDate.slice(0, 2)}${paymentMethod.expiryDate.substr(-2)}` || this.props.cardDetail.CcExpMonth,
                cardtype: this.props?.cardDetail?.CardType,
                CompanyId: auth.user.CompanyId,
                cvv: paymentMethod.cvv
            }
            apiService.post("UPDATEPAYMENTMETHOD", data).then((response) => {
                this.props.onClose()
                this.setState({ paymentMethod: { firstName: '', lastName: '', city: '', state: '', zip: '', address: '' }, isInvalidState: false, isLoading: false })

                if (response.Success) { this.props.actions.showAlert({ message: "Card updated successfully", variant: 'success' }); } else { this.props.actions.showAlert({ message: response.Message, variant: 'error' }); }
                this.props.GetPaymentMethods()
            }, error => {

            })
        }

    }

    handleChange = (e) => {
        const { paymentMethod } = this.state;
        const expiration = new Date().getFullYear().toString().slice(-2)
        paymentMethod[e.target.name] = e.target.value;
        this.setState({ paymentMethod });
        if (e.target.name === "state" && e.target.value) this.setState({ isInvalidState: false })
        if (e.target.name === "expiryDate" && e.target.value.substr(-2) >= expiration) { this.setState({ isInvalidExpiration: false }) }
    };

    closeModal = () => {
        this.props.onClose()
        this.setState({ paymentMethod: { firstName: '', lastName: '', city: '', state: '', zip: '' }, isInvalidState: false, isInvalidExpiration: false })

    };

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    checkValidations = () => {
        const { paymentMethod } = this.state
        this.state.paymentMethod.state === "" && this.setState({ isInvalidState: true })

        //Validate card expiration
        const month = new Date().getMonth() + 1
        const expiryMonth = month.toString()
        const year = new Date().getFullYear().toString().slice(-2)
        const cardMonth = Number(paymentMethod.expiryDate?.slice(0, 2))
        const cardYear = paymentMethod.expiryDate?.substr(-2)
        cardYear < year ? this.setState({ isInvalidExpiration: true }) : this.setState({ isInvalidExpiration: false })
        if (cardYear === year) {
            cardMonth < expiryMonth ? this.setState({ isInvalidExpiration: true }) : this.setState({ isInvalidExpiration: false })
        }
    }

    ExpiryDateFormat = (event) => {
        event.target.value = event.target.value.replace(
            /[^0-9]/g, '' // To allow only numbers
        ).replace(
            /^([2-9])$/g, '0$1' // To handle 3 > 03
        ).replace(
            /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
        ).replace(
            /^0{1,}/g, '0' // To handle 00 > 0
        ).replace(
            /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
        );
    };

    componentDidMount() {
        this.getGlobalCodes();
      };

    render() {
        const { paymentMethod, isLoading, isInvalidState, isInvalidExpiration,states } = this.state;
        return (
            <React.Fragment>
                <Modal
                    show={this.props.showUpdateModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    // keyboard={false}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title"> Update Payment Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "100%" }}  >
                        {isLoading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                        <ValidatorForm action="/action_page.php" method="get" id="nameform" onSubmit={this.handleSubmit} >
                            <div class="row">
                                <div class="col">
                                    <span className='form-label' >First Name</span>
                                    <TextValidator validators={['required']} errorMessages={['First Name is Required.']}
                                        type="text" className="form-control" placeholder="First Name" name="first_name" onChange={this.handleChange} value={paymentMethod.first_name} />
                                    <span className="form-validation"></span>
                                </div>
                                <div class="col">
                                    <span className='form-label'>Last Name</span>
                                    <TextValidator validators={['required']} errorMessages={['Last Name is Required.']} type="text" class="form-control" placeholder="Last Name" name="last_name" onChange={this.handleChange} value={paymentMethod.last_name} />
                                </div>
                            </div>
                            <div class="row" >
                                <div class="col">
                                    <span className='form-label'>Address</span>
                                    <TextValidator validators={['required']} errorMessages={['Address is Required.']} type="text" class="form-control" placeholder="Address" name="address" onChange={this.handleChange} value={paymentMethod.address} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span className='form-label'>City</span>
                                    <TextValidator validators={['required']} errorMessages={['City is Required.']} type="text" class="form-control" placeholder="City" name="city" onChange={this.handleChange} value={paymentMethod.city} />
                                </div>
                                <div class="col">
                                    <span className='form-label'>State</span>
                                    <NativeSelect className='native-select' onChange={this.handleChange} id="state" name="state" >
                                        <option value="" >Select State</option>
                                        {states.map((item, index) => {
                                            return (
                                                <option key={index} >{item?.Description}</option>
                                            )
                                        })}
                                    </NativeSelect>
                                    {isInvalidState && <span className='error' >State is Required</span>}
                                </div>
                                <div class="col">
                                    <span className='form-label'>ZIP</span>
                                    <TextValidator validators={['required']} errorMessages={['ZIP is Required.']} type="number" maxLength="8" class="form-control" placeholder="ZIP" name="zip" onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onInput={this.maxLengthCheck} onChange={this.handleChange} value={paymentMethod.zip} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span className='form-label'>Card Number</span>
                                    <TextValidator type="text" class="form-control" name="cardNumber" style={{ fontWeight: "bold", color: "#495057" }} value={`**** **** **** ${this.props?.cardDetail?.CardNumber}` || ""} disabled />
                                </div>
                                <div class="col">
                                    <span className='form-label'>Card Expiration</span>
                                    <TextValidator type="text" value={paymentMethod.expiryDate} placeholder={`${this.props?.cardDetail?.CcExp.slice(0, 2)}/${this.props?.cardDetail?.CcExp.slice(2)}`} onChange={this.handleChange} onKeyUp={(e) => this.ExpiryDateFormat(e)} maxLength="5" class="form-control" name="expiryDate" />
                                    {isInvalidExpiration && <span className='error' >Please enter valid card expiration.</span>}

                                </div>
                                <div class="col">
                                    <span className='form-label'>CVV</span>
                                    <TextValidator type="number" maxLength="4" validators={['required']} errorMessages={['CVV is Required.']} onInput={this.maxLengthCheck} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} class="form-control" placeholder="CVV" name="cvv" onChange={this.handleChange} value={paymentMethod.cvv} />
                                </div>

                            </div>
                        </ValidatorForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Cancel
                        </Button>

                        <button type="submit" class="btn btn-primary" form="nameform" value="Submit" onClick={this.checkValidations} >Save</button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethodModal);;