import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Scrollbars } from 'react-custom-scrollbars';
import CircularProgress from '@material-ui/core/CircularProgress';

class MessageSidebar extends Component {
    render() {
        const { kioskMessagingGroupsList, loadingGroupList, changeGroupHandler, activeGroupDetail } = this.props;
        return (
            <div className="m-sidebar">
                <header>
                    <h2 className="hidden-xs">Messages</h2>
                    <ul className="actions">
                        <li>
                            <i className="fa fa-weixin"></i>
                        </li>
                    </ul>
                </header>
                <div className="list-group c-overflow">
                    <Scrollbars className="scrollBar" autoHide >
                        {!loadingGroupList && kioskMessagingGroupsList && kioskMessagingGroupsList.length > 0 && kioskMessagingGroupsList.map((item, key) => {
                            return (<button type="button" key={key} className={`btn btn-link stepperDetailIcon list-group-item media ${item.NewMessageRecevied ? 'new-message-recevied' : ''} ${activeGroupDetail.FriendlyName && activeGroupDetail.FriendlyName === item.FriendlyName ? 'active' : ''}`} onClick={() => changeGroupHandler(item,key)}>
                                <div className="pull-left">
                                    <Avatar alt={item.FriendlyName} src="/static/images/avatar/1.jpg" />
                                </div>
                                <div className="media-body">
                                    <div className="lgi-heading">{item.FriendlyName}</div>
                                    {/* <small className="ms-time">05:00 PM</small> */}
                                </div>
                            </button>)
                        })
                        }
                        {loadingGroupList && (
                            <div className="loaderDiv">
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </Scrollbars>
                </div>
            </div>

        );
    }
}

export default MessageSidebar;
