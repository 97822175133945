

export const Questions = [
    {
        "count": 1,
        "question": "It is okay to commit crime to pay for the things you want",
        "scoreField": "INScore"
    },
    {
        "count": 2,
        "question": "Please fill in the “Agree” box as your response for this question",
        "scoreField": ""
    },
    {
        "count": 3,
        "question": "When you are upset, you act without thinking",
        "scoreField": "RDScore"
    },
    {
        "count": 4,
        "question": "When you are arrested or locked-up, it’s because you had a run of bad luck",
        "scoreField": "JUScore"
    },
    {
        "count": 5,
        "question": "You have never deliberately said something that hurt someone’s feelings",
        "scoreField": "SDScore"
    },
    {
        "count": 6,
        "question": "If someone disrespects you, then you have to straighten them out",
        "scoreField": "POScore"
    },
    {
        "count": 7,
        "question": "You should not be held responsible for the crimes you have committed",
        "scoreField": "GRScore"
    },
    {
        "count": 8,
        "question": "It is okay to lie and manipulate others to get what you want",
        "scoreField": "INScore"
    },
    {
        "count": 9,
        "question": "When you feel rejected, you say things that you later regret",
        "scoreField": "RDScore"
    },
    {
        "count": 10,
        "question": "You are sometimes irritated by people who ask favors of you",
        "scoreField": "SDScore"
    },
    {
        "count": 11,
        "question": "When asked about your motives for engaging in crime, you point out how hard your life has been",
        "scoreField": "JUScore"
    },
    {
        "count": 12,
        "question": "When you do not know something, you do not at all mind admitting it",
        "scoreField": "SDScore"
    },
    {
        "count": 13,
        "question": "You must get back at people who mess with you",
        "scoreField": "POScore"
    },
    {
        "count": 14,
        "question": "You expect to be treated better than the people around you",
        "scoreField": "GRScore"
    },
    {
        "count": 15,
        "question": "Breaking the law is no big deal if you do not physically harm someone",
        "scoreField": "INScore"
    },
    {
        "count": 16,
        "question": "It is hard for you to resist acting on your emotions",
        "scoreField": "RDScore"
    },
    {
        "count": 17,
        "question": "You find yourself blaming the victims of some of your crimes",
        "scoreField": "JUScore"
    },
    {
        "count": 18,
        "question": "You are willing to take advantage of others to get what you want",
        "scoreField": "POScore"
    },
    {
        "count": 19,
        "question": "Your thoughts and ideas are better than the people around you",
        "scoreField": "GRScore"
    },
    {
        "count": 20,
        "question": "You are always willing to admit it when you make a mistake",
        "scoreField": "SDScore"
    },
    {
        "count": 21,
        "question": "When you are upset, you make matters worse because you act without thinking",
        "scoreField": "RDScore"
    },
    {
        "count": 22,
        "question": "It is okay to commit a crime to live the life you deserve",
        "scoreField": "INScore"
    },
    {
        "count": 23,
        "question": "When you feel overwhelmed, you have a difficult time making good decisions",
        "scoreField": "RDScore"
    },
    {
        "count": 24,
        "question": "The only way to protect yourself is to be ready to fight",
        "scoreField": "POScore"
    },
    {
        "count": 25,
        "question": "When you are angry, you do things that have negative or bad consequences",
        "scoreField": "RDScore"
    },
    {
        "count": 26,
        "question": "You can remember “playing sick” to get out of something",
        "scoreField": "SDScore"
    },
    {
        "count": 27,
        "question": "You justify the crimes you commit by telling yourself that if you had not done it, someone else would have",
        "scoreField": "INScore"
    },
    {
        "count": 28,
        "question": "You are not to blame for everything you have done",
        "scoreField": "JUScore"
    },
    {
        "count": 29,
        "question": "No matter who you are talking to, you are always a good listener",
        "scoreField": "SDScore"
    },
    {
        "count": 30,
        "question": "You deserve to live a better life than the people around you",
        "scoreField": "GRScore"
    },
    {
        "count": 31,
        "question": "You feel the need to get back at someone who disrespects you",
        "scoreField": "POScore"
    },
    {
        "count": 32,
        "question": "The victims of some of your crimes were asking for it",
        "scoreField": "INScore"
    },
    {
        "count": 33,
        "question": "You find yourself blaming society and external circumstances for your problems with the justice system",
        "scoreField": "JUScore"
    },
    {
        "count": 34,
        "question": "You become upset when people do not do what you tell them to do",
        "scoreField": "GRScore"
    },
    {
        "count": 35,
        "question": "You sometimes get mad when you do not get your way",
        "scoreField": "SDScore"
    },
    {
        "count": 36,
        "question": "When you are angry, you do not think of the consequences of your actions",
        "scoreField": "RDScore"
    },
]



export const ResultQuestions = [
    {
        "question": "Insensitivity to Impact of Crime Scale",
        "scoreField": "INScore",
        "avgValue": 0
    },
    {
        "question": "Response Disinhibition Scale",
        "scoreField": "RDScore",
        "avgValue": 0
    },
    {
        "question": "Justification Scale",
        "scoreField": "JUScore",
        "avgValue": 0
    },
    {
        "question": "Power Orientation Scale",
        "scoreField": "POScore",
        "avgValue": 0
    },
    {
        "question": "Grandiosity Scale",
        "scoreField": "GRScore",
        "avgValue": 0
    },
    {
        "question": "Social Desirability Scale ",
        "scoreField": "SDScore",
        "avgValue": 0
    },
];