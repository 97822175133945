import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from "../../store/actions";
import { apiService } from "../../services/api.service";
class CardGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            vaultId: '',
            isLoading: false
        };
        this.removeCard = this.removeCard.bind(this)
    };
    removeCard = (e) => {
        const { auth } = this.props;
        const vaultId = this.state.vaultId
        const data = { ClientId: auth?.user?.ClientId, CompanyId: auth?.user?.CompanyId, CustomerVaultId: vaultId }
        apiService.post('DELETEPAYMENTMETHOD', data).then((response) => {
            this.Close()
            this.props.GetPaymentMethods()
            this.props.actions.showAlert({ message: "Card removed successfully", variant: 'success' });
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    };

    openConfirmationModal = (e, item) => {
        this.setState({ vaultId: item.CustomerVaultId })
        this.setState({ showModal: true })
    }
    Close = () => {
        this.setState({ showModal: false });
    };
    render() {
        return (
            <Fragment>
                <div className="main-card-wrapper">
                    {this.props?.PaymentMethods?.map((item, index) => {
                        return (
                            <div key={index} className="main-card">
                                <div className="card-num"><div className="hiden-card-numbers">**** **** ****</div> {item.CardNumber}</div>
                                <div className="exp-date"><span className="label">Exp.</span>{item?.CcExp.slice(0, 2)}/{item?.CcExp.slice(2)}</div>
                                <div className="main-card-footer">
                                    <div className="card-type">{item?.CardType?.toUpperCase()}</div>
                                    <div>
                                        <button type="submit" className="btn btn-primary btn-md waves-effect waves-light text-center mr-2" onClick={(e) => this.props.openUpdatePaymentMethodModal(e, item)} >Update</button>
                                        <button type="submit" className="btn btn-primary btn-md waves-effect waves-light text-center" onClick={(e) => this.openConfirmationModal(e, item)} >Remove</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="add-new-card" title="Add new card" onClick={(e) => this.props.openPaymentMethodModal(e)} >+</div>
                    {this.props.isLoading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={this.Close}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "90px" }}>
                        Are you sure you want to remove this saved payment method?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.Close}>
                            Cancel
                        </Button>
                        <Button onClick={this.removeCard} >Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardGrid);
