import React, { Component, Fragment } from "react";
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import { apiService } from "../../services/api.service";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Paper, Toolbar, Typography } from "@material-ui/core";

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kioskAnnouncements: []
    };
  }
  GetKioskAnnouncements = () => {
    const { auth } = this.props;

    apiService
      .post("GETKIOSKANNOUNCEMENTS", {
        Id: -1,
        Expired: true,
        CompanyId: auth.user.CompanyId,
      })
      .then(
        (response) => {
          if (response.Success && response.announcementLists) {
            this.setState({
              kioskAnnouncements: response.announcementLists,
            });
          }
        },
        (error) => {
          this.props.actions.showAlert({ message: error, variant: "error" });
        }
      );
  };

  componentDidMount() {
    this.GetKioskAnnouncements();
  }
  render() {
    const { kioskAnnouncements } = this.state;
    const createMarkup = (htmlString) => ({ __html: htmlString });
    return (
      <Fragment>
        <Paper style={{ paddingBottom: 10 }}>
          <Toolbar>
            <Typography variant="h6">Announcements</Typography>
          </Toolbar>
          {
            kioskAnnouncements && kioskAnnouncements.map((a) =>
              <div className="card detailPage" key={a.Id}>
                <div className="card-header positiveincident">
                  <div className="row">
                    <div className="col-md-9 col-sm-9">
                      {a.Headline}
                    </div>
                    <div className="col-md-3 col-sm-3">
                      Expires <FormatDateTime
                        date={a.ExpirationDate}
                        format="MM/DD/YYYY"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="longtext"
                    dangerouslySetInnerHTML={createMarkup(a.Announcement)}
                  />
                </div>
              </div>
            )
          }
        </Paper>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      showAlert: bindActionCreators(actions.showAlert, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
