import React from 'react';
import { ResultQuestions, Questions } from './questionsJson';
import { Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Checkbox } from '@material-ui/core';
import { apiService } from '../../services/api.service';
import * as actions from '../../store/actions';
import { Paper, Toolbar, Typography } from "@material-ui/core";
import { history } from '../../helpers/history'
import { connect } from 'react-redux';


class AssessmentSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resultQuestions: ResultQuestions,
            questionsData: Questions,
            surveyValues: {
                KioskClientAssessmentsAssignedId: ''
            },
            isChecked: false,
            formSubmitted: false,
            disableSave: false,
            showModal: false,
        };
        this.requiredThresholds = {};
    }

    handleOptionChange = (questionIndex, optionValue) => {
        const { questionsData } = this.state;
        const updatedQuestionsData = [...questionsData];
        const updatedQuestion = { ...updatedQuestionsData[questionIndex] };

        if (updatedQuestion.value === optionValue) {
            updatedQuestion.value = undefined;
        } else {
            updatedQuestion.value = optionValue;
        }

        updatedQuestionsData[questionIndex] = updatedQuestion;

        const surveyValues = {};
        updatedQuestionsData.forEach((question, index) => {
            const surveyName = `Survey${index + 1}`;
            const surveyValue = question.value || 0;
            surveyValues[surveyName] = surveyValue;
        });

        const isChecked = updatedQuestionsData.some((question) => question.value !== undefined);

        const { rowData } = this.props.location.state;
        this.setState({
            surveyValues: { ...surveyValues, KioskClientAssessmentsAssignedId: rowData?.KioskClientAssessmentsAssignedId },
            questionsData: updatedQuestionsData,
            isChecked,
        });
    };

    redirectBack = () => {
        const initialQuestionsData = Questions.map(question => ({
            ...question,
            value: undefined,
        }));
        this.setState({ questionsData: initialQuestionsData, resultQuestions: ResultQuestions, surveyValues: {}, });
        history.push('/Assessments')
    }

    handleCancelAssessment = () => {
        if (this.state.isChecked)
            this.toggleModal();
        else {
            this.redirectBack()
        }
    }

    calculateAverageForScale = (scoreField) => {
        const { questionsData } = this.state;
        const scaleQuestions = questionsData.filter(
            (question) => question.scoreField === scoreField
        );
        const selectedCount = scaleQuestions.filter((question) => question.value !== undefined).length;
        const total = scaleQuestions.reduce((acc, question) => {
            return acc + (question.value || 0);
        }, 0);
        const average = total * 100;
        const scaledAverage = average / selectedCount / 10;
        return scaledAverage;
    };

    submitForm = () => {
        const { surveyValues, questionsData } = this.state;
        const { auth } = this.props;
        this.setState({ formSubmitted: true, });
        const allQuestionsAnswered = questionsData.every((question) => question.value !== undefined);

        if (allQuestionsAnswered) {
            this.setState({ disableSave: true })
            const updatedResultQuestions = this.state.resultQuestions.map((scale) => ({
                ...scale,
                avgValue: this.calculateAverageForScale(scale.scoreField),
            }));
            updatedResultQuestions.forEach((question) => {
                const name = question.scoreField;
                const value = question.avgValue || 0;
                surveyValues[name] = value;
            });
            this.setState({
                surveyValues: { ...surveyValues },
            });

            const data = { Json: JSON.stringify(surveyValues), CompanyId: auth.user.CompanyId, ModifiedBy: auth.user.UserName };
            apiService.post('INSERTCLIENTASSESSMENT', data).then(
                (response) => {
                    if (response.Success) {
                        this.props.actions.showAlert({ message: response.Message, variant: 'success' });
                    }
                    history.push('/Assessments');
                },
                (error) => {
                    this.props.actions.showAlert({ message: error, variant: 'error' });
                    this.setState({ loading: false });
                }
            );
        } else {
            this.props.actions.showAlert({
                message: 'Please answer all questions.',
                variant: 'error',
            });
        }
    };

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    render() {
        const { questionsData, disableSave } = this.state;
        return (
            <>
                <div className="backToAccount">
                    <button type="button" className="btn btn-link" onClick={this.handleCancelAssessment}><i className="fa fa-arrow-left"></i> Back</button>
                </div>
                <Paper style={{ paddingBottom: 10 }}>
                    <Toolbar>
                        <Typography variant="h2">Survey</Typography>
                    </Toolbar>
                    <div className='assessment-table-container'>
                        <table className='assessment-table-header'>
                            <thead>
                                <tr>
                                    <th>Please mark how much you AGREE or DISAGREE with each statement.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {questionsData.map((question, questionIndex) => (
                                    <tr key={questionIndex}>
                                        <td className={`question-text ${this.state.formSubmitted && question.value === undefined ? 'unselected-question' : ''}`}>{`${questionIndex + 1}.  ${question.question}`}</td>
                                        <td className='assessment-checkboxes' >
                                            {[1, 2, 3, 4, 5].map((optionValue, optionIndex) => (
                                                <label key={optionValue}>
                                                    <Checkbox
                                                        name={`question${questionIndex}`}
                                                        value={optionValue}
                                                        checked={question.value === optionValue}
                                                        onChange={() => this.handleOptionChange(questionIndex, optionValue)} ></Checkbox>
                                                    <span className="assessment-option-label">
                                                        {['Strongly Disagree', 'Disagree', 'Uncertain', 'Agree', 'Strongly Agree'][optionIndex]}
                                                    </span>
                                                </label>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ margin: '20px' }} className='surveyButtons' >
                        <Button variant="secondary" onClick={this.handleCancelAssessment}>
                            Cancel
                        </Button>
                        <Button
                            disabled={disableSave}
                            className="btn btn-primary"
                            onClick={this.submitForm}
                        >
                            Save
                        </Button>
                    </div>
                </Paper >
                <Modal
                    show={this.state.showModal}
                    onHide={this.toggleModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "90px" }}>
                        Are you sure you want to cancel this Assessment?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleModal}>
                            No
                        </Button>
                        <Button onClick={this.redirectBack} >Yes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSurvey);
