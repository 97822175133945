
import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { history } from '../../helpers/history'
import FormatDateTime from '../../shared/components/functional/DateTimeFormatter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from "../../store/actions";
import { apiService } from '../../services/api.service';


class Surveys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SurveyData: [],
            isLoading: true
        }
    }

    GetKioskClientSurveys = () => {
        const { auth } = this.props;
        const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
        apiService.post('GETKIOSKCLIENTSURVEYS', data).then((response) => {
            this.setState({ SurveyData: response.KioskClientSurveys, isLoading: false })
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    handleOpenSurveyForm = (rowData) => {
        const { auth } = this.props
        history.push({
            pathname: '/Surveys/AddSurvey',
            state: { rowData: rowData, auth: auth, }
        })
    }

    componentDidMount() {
        this.GetKioskClientSurveys()
    }

    render() {
        const { SurveyData, isLoading } = this.state
        return (
            <>
                <MaterialTable
                    columns={[
                        {
                            title: "Select",
                            field: "Description",
                            sorting: false,
                            render: rowData =>
                                <>
                                    <div data-testid="td-before" className="tdBefore">Select</div>
                                    <i className="fa fa-circle-o" aria-hidden="true" onClick={(e) => { this.handleOpenSurveyForm(rowData) }} ></i>
                                </>
                        },
                        {
                            title: "Survey Type",
                            field: "SurveyTemplateName",
                            sorting: true,
                            render: rowData => {
                                return (
                                    <> <div data-testid="td-before" className="tdBefore">Survey Type</div>{rowData.SurveyTemplateName}</>)
                            }
                        },
                        {
                            title: "Assigned Date",
                            field: "AssignedDate",
                            sorting: true,
                            render: rowData => <><div data-testid="td-before" className="tdBefore">Assigned Date</div>
                                <FormatDateTime
                                    date={rowData.AssignedDate}
                                    format="MM/DD/YYYY"
                                />
                            </>
                        },
                    ]}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [5, 10, 25, 50, 100],
                        toolbar: true,
                        paging: true,
                        paginationType: "normal",
                        view: true,
                        headerStyle: {
                            backgroundColor: '#4f656c',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        minBodyHeight: "300px",
                        draggable: false
                    }}
                    isLoading={isLoading}
                    data={SurveyData || []}

                    localization={{
                        body: {
                            emptyDataSourceMessage: "There are no Surveys to display"
                        },
                        pagination: {
                            labelRowsSelect: "rows per page"
                        }
                    }}
                    title="Surveys"
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);


