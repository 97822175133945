import Helpfulink from "../containers/helpfullink/helpfullink";
import ChangePassword from "../containers/account/changepassword";
import Login from "../containers/account/login";
import Home from "../containers/home/index";
import ResourceLibrary from "../containers/resourcelibrary/resourcelibrary";
import Reminders from "../containers/reminders/reminders";
import Agenda from "../containers/agenda/agenda_index";
import AddAgenda from "../containers/agenda/add_agenda";
import StaffLogin from "../containers/account/stafflogin";
import RecordAmounts from "../containers/community_financial/payments/payment_index";
import Financial from "../containers/financial/financial";
import CheckIn from "../containers/check-ins/checkins_capture";
import History from "../containers/check-ins/history";
import UploadImage from "../containers/ClientPhoto/upload_image";
import Announcements from "../containers/announcements/announcements";
import PositiveIncidents from "../containers/positiveincidents/positiveincidents";
import Messages from "../containers/messages";
import CommunityFinancial from "../containers/community_financial";
import Wallet from "../containers/wallet";
import Contract from "../containers/contract/contract";
import Surveys from "../containers/surveys/surveys";
import AddSurvey from "../containers/surveys/addSurvey";
import AssessmentsGrid from "../containers/assessments/assessmentGrid";
import AssessmentSurvey from "../containers/assessments/assessmentSurvey"
import Sanctions from '../containers/sanctions/sanctions_details'
var appRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    exact: true,
    auth: true,
  },
  {
    path: "/account/changepassword",
    name: "Change Password",
    component: ChangePassword,
    exact: true,
    auth: true,
  },
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    exact: true,
  },
  {
    path: "/HelpFulLinks",
    name: "Help Full Link",
    component: Helpfulink,
    exact: true,
    auth: true,
  },
  {
    path: "/ResourceLibrary",
    name: "Resource Library",
    component: ResourceLibrary,
    exact: true,
    auth: true,
  },
  {
    path: "/Reminder",
    name: "Reminder",
    component: Reminders,
    exact: true,
    auth: true,
  },
  {
    path: "/Financial",
    name: "Financial",
    component: Financial,
    exact: true,
    auth: true,
  },
  {
    path: "/CommunityFinancial",
    name: "CommunityFinancial",
    component: CommunityFinancial,
    exact: true,
    auth: true,
  },
  {
    path: "/CommunityFinancial/RecordPayment",
    name: "RecordPayment",
    component: RecordAmounts,
    exact: true,
    auth: true,
  },
  {
    path: "/Agenda",
    name: "Agenda",
    component: Agenda,
    exact: true,
    auth: true,
  },
  {
    path: "/Agenda/Add_Agenda",
    name: "AddAgenda",
    component: AddAgenda,
    exact: true,
    auth: true,
  },
  {
    path: "/Account/StaffLogin",
    name: "Staff Login",
    component: StaffLogin,
    exact: true,
  },
  {
    path: "/checkin/capture",
    name: "CheckIn",
    component: CheckIn,
    exact: true,
    auth: true,
  },
  {
    path: "/checkin/history",
    name: "History",
    component: History,
    exact: true,
    auth: true,
  },
  {
    path: "/clientphoto/upload",
    name: "UploadImage",
    component: UploadImage,
    exact: true,
    auth: true,
  },
  {
    path: "/Announcement",
    name: "Announcement",
    component: Announcements,
    exact: true,
    auth: true,
  },
  {
    path: "/PositiveIncidents",
    name: "Positive Incidents",
    component: PositiveIncidents,
    exact: true,
    auth: true,
  },
  {
    path: "/Messages",
    name: "Messages",
    component: Messages,
    exact: true,
    auth: true,
  },
  {
    path: "/Wallet",
    name: "Wallet",
    component: Wallet,
    exact: true,
    auth: true,
  },
  {
    path: "/Contract",
    name: "Contract",
    component: Contract,
    exact: true,
    auth: true,
  },
  {
    path: "/Surveys",
    name: "Surveys",
    component: Surveys,
    exact: true,
    auth: true,
  },
  {
    path: "/Surveys/AddSurvey",
    name: "AddSurvey",
    component: AddSurvey,
    exact: true,
    auth: true,
  },
  {
    path: "/Assessments",
    name: "Assessments",
    component: AssessmentsGrid,
    exact: true,
    auth: true,
  },
  {
    path: "/Assessments/Details",
    name: "AssessmentDetails",
    component: AssessmentSurvey,
    exact: true,
    auth: true,
  },
  {
    path: "/Sanctions",
    name: "Sanctions",
    component: Sanctions,
    exact: true,
    auth: true,
  },
];

export default appRoutes;
