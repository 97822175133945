import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import { apiService } from '../../../../services/api.service';
import TextValidator from '../form/text-validator';

class MeridianPaymentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            isInValidCardNumber: false,
            isInValidCvv: false,
            isInValidCardExp: false,
            isInvalidState: false
        };
    }

    initForm = () => {
        // this.setState({ isLoading: true });
        // setTimeout(() => {
        //     this.setState({ isLoading: false });
        // }, 500);
        window.CollectJS.configure({
            variant: 'inline',
            styleSniffer: true,
            callback: (callbackResponse) => {
                this.props.meridianPaymentCall(callbackResponse);
            },
            fields: {
                ccnumber: { placeholder: 'Card Number', selector: '#ccnumber', },
                ccexp: { placeholder: 'MM/YY', selector: '#ccexp' },
                cvv: { placeholder: 'CVV', selector: '#cvv' },
            },
        });
    };

    checkMeridianFormValid = () => {
        const { meridianPaymentMethod } = this.props;
        const valid = 'CollectJSInlineIframe CollectJSValid';
        const iframeSelector = window.CollectJS.iframes;
        this.setState({
            isInValidCardNumber: iframeSelector.ccnumber.className !== valid,
            isInValidCardExp: iframeSelector.ccexp.className !== valid,
            isInValidCvv: iframeSelector.cvv.className !== valid,
        });
        meridianPaymentMethod.state === "" && this.setState({ isInvalidState: true })
        if (meridianPaymentMethod.state !== "") {
            return (
                iframeSelector.ccnumber.className === valid &&
                iframeSelector.ccexp.className === valid &&
                iframeSelector.cvv.className === valid
            );
        }
    }

    handleBlur = () => {
        setTimeout(() => {
            this.checkMeridianFormValid();
        }, 100);
    }

    getGlobalCodes = () => {
        this.setState({ loading: true });
        const { handleLoding } = this.props;
        var requestdata = { CategoryName: 'state', RecordDeleted: 'N' };
        apiService.post('GETGLOBALCODES', requestdata).then(response => {
            this.setState({
                states: response.GlobalCode
            });
            handleLoding(true);
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        }).finally(() => {
            setTimeout(() => {
                handleLoding(false);
            }, 750);
        });
    }

    componentDidMount() {
        this.getGlobalCodes();
        this.initForm();
    }

    markStateValid() {
        this.setState({ isInvalidState: false });
    }

    render() {
        const { handleSubmit, handleChange, meridianPaymentMethod } = this.props;
        const { states, isInValidCardNumber, isInValidCvv, isInValidCardExp, isInvalidState } = this.state;

        return (
            <>
                <div>
                    <ValidatorForm
                        action="/action_page.php"
                        method="get"
                        id={"nameform"}
                        onSubmit={handleSubmit}
                    >
                        <div className="row">
                            <div className="col">
                                <span className="form-label">First Name</span>
                                <TextValidator
                                    validators={['required']}
                                    errorMessages={['First Name is Required.']}
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    name="first_name"
                                    onChange={handleChange}
                                    value={meridianPaymentMethod.first_name}
                                />
                            </div>
                            <div className="col">
                                <span className="form-label">Last Name</span>
                                <TextValidator
                                    validators={['required']}
                                    errorMessages={['Last Name is Required.']}
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    name="last_name"
                                    onChange={handleChange}
                                    value={meridianPaymentMethod.last_name}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="form-label">Address</span>
                                <TextValidator
                                    validators={['required']}
                                    errorMessages={['Address is Required.']}
                                    type="text"
                                    className="form-control"
                                    placeholder="Address"
                                    name="address"
                                    onChange={handleChange}
                                    value={meridianPaymentMethod.address}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span className="form-label">City</span>
                                <TextValidator
                                    validators={['required']}
                                    errorMessages={['City is Required.']}
                                    type="text"
                                    className="form-control"
                                    placeholder="City"
                                    name="city"
                                    onChange={handleChange}
                                    value={meridianPaymentMethod.city}
                                />
                            </div>
                            <div className="col">
                                <span className="form-label">State</span>
                                <NativeSelect className='native-select' onChange={handleChange} id="state" name="state" value={meridianPaymentMethod.state} >
                                    <option value="" >Select State</option>
                                    {states?.map((item, index) => {
                                        return (
                                            <option key={index} >{item?.Description}</option>
                                        )
                                    })}
                                </NativeSelect>
                                {isInvalidState && <span className='error' >State is Required</span>}
                            </div>
                            <div className="col">
                                <span className="form-label">ZIP</span>
                                <TextValidator
                                    validators={['required']}
                                    errorMessages={['ZIP is Required.']}
                                    type="number"
                                    className="form-control"
                                    placeholder="ZIP"
                                    name="zip"
                                    maxLength="8"
                                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onChange={handleChange}
                                    value={meridianPaymentMethod.zip}
                                />
                            </div>
                        </div>

                        <div className="row gap-1">
                            <div className="col" >
                                <span className="form-label">Card Number</span>
                                <div className='Form'>
                                    <div id="ccnumber" className="input-group" onBlur={this.handleBlur} />
                                    {isInValidCardNumber && (
                                        <span className="error">Valid Card Number is Required</span>
                                    )}
                                </div>
                            </div>
                            <div className="col">
                                <span className="form-label">Card Expiration</span>
                                <div id="ccexp" onBlur={this.handleBlur} />
                                {isInValidCardExp && (
                                    <span className="error">Valid Card Expiration is Required</span>
                                )}
                            </div>
                            <div className="col">
                                <span className="form-label">CVV</span>
                                <div id="cvv" onBlur={this.handleBlur} />
                                {isInValidCvv && (
                                    <span className="error">Valid CVV is Required</span>
                                )}
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </>
        );
    }

}

export default MeridianPaymentForm;