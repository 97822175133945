import React from 'react';
import CurrencyFormatter from '../../../shared/components/functional/CurrencyFormatter';
class PaymentAmounts extends React.Component {
    render() {
        return (
            <>
                <div className='payment-amount-table-container' >
                    <table className='payment-amount-table'>
                        <thead>
                            <tr>
                                <th>Account</th>
                                <th>Current Balance</th>
                                <th>Payment </th>
                            </tr>
                        </thead>
                        {this.props.enrollmentDataTotal?.CurrentBalance === 0 ?
                            <td colSpan={3} className="contentCenter">There are no active enrollments</td> : this.props.enrollmentData.map((enrollmentDetail, enrollmentIndex) => {
                                return <>
                                    <tr key={enrollmentIndex}>
                                        <td>{enrollmentDetail.Description}</td>
                                        <td>{enrollmentDetail.Services.length === 0 && <CurrencyFormatter currency={enrollmentDetail.CurrentBalance} />}</td>
                                        <td>{enrollmentDetail.Services?.length < 1 && <input className="form-control" maxLength="13" style={{ width: "200px" }} min="0" type="number" 
                                        data-enrollmentIndex={enrollmentIndex} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                        defaultValue={enrollmentDetail.PaymentBalance} onChange={(e) => this.props.handleUpdateBalance(e, "EnrollmentPaymentBalance")} />}</td>
                                    </tr>
                                    {enrollmentDetail.Services?.map((serviceDetail, serviceIndex) => {
                                        return <tr key={serviceIndex} className='service-row'>
                                            <td>{serviceDetail.Service}</td>
                                            <td className='service-balance' style={{ position: "relative", right: "20px" }}><CurrencyFormatter currency={serviceDetail.CurrentBalance} /></td>
                                            <td><input className="form-control" maxLength="13" type="number" min="0" style={{ width: "200px", position: "relative", right: "20px" }} defaultValue={serviceDetail.PaymentBalance} onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} data-enrollmentIndex={enrollmentIndex} data-serviceIndex={serviceIndex} onChange={(e) => this.props.handleUpdateBalance(e, "ServicePaymentBalance")} /></td>
                                        </tr>
                                    })}
                                </>
                            })
                        }
                        <tfoot>
                         {this.props.enrollmentDataTotal.CurrentBalance === 0 ? "" : <tr>
                                <td>Total</td>
                                <td> <CurrencyFormatter currency={this.props.enrollmentDataTotal.CurrentBalance} /></td>
                                <td> {this.props.enrollmentDataTotal.PaymentBalance === 0 ? 
                                    <CurrencyFormatter currency={this.props.enrollmentDataTotal.PaymentBalance} /> : 
                                    <CurrencyFormatter currency={this.props.enrollmentDataTotal.PaymentBalance} />}
                                </td>
                        </tr>}
                        </tfoot>
                    </table>
                </div>
            </>
        )
    }

}



export default PaymentAmounts