import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CommunityFinancialGrid from './community_financial_grid';
import { history } from "../../helpers/history";
import { apiService } from "../../services/api.service";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import CommunityFinancialTransactionDetail from './community_financial_transaction_details';
import CommunityFinancialTransactionModal from '../../shared/components/ui/modal/community-financial-transaction-modal';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

class CommunityFinancial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            classes: makeStyles(theme => ({
                root: {
                    flexGrow: 1,
                    backgroundColor: theme.palette.background.paper
                }
            }
            )),
            isLoading: true,
            openAccountDetail: [],
            closedAccountDetail: [],
            transactionDetail: [],
            showTransactionDetail: false,
            showModal: false,
            isCommunity: "",
            isStaffLogin: false
        };
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleShowAlert = data => {
        this.props.actions.showAlert(data);
    };
    GetClientResidentAccounts = (Closed) => {
        this.setState({ isLoading: true });
        const { auth } = this.props;
        let data = { ClientId: auth.user.ClientId, AllAccounts: Closed ? 1 : 0 };
        apiService.post('GETCOMMUNITYACCOUNTSUMMARIES', data).then(
            response => {
                if (response.Success && response.CommunityAccountSummaries) {
                    var CommunityAccountSummaries = response.CommunityAccountSummaries;
                    if (Closed) {
                        this.setState({ closedAccountDetail: CommunityAccountSummaries });
                    }
                    else {
                        this.setState({ openAccountDetail: CommunityAccountSummaries });
                    }
                }
                this.setState({ isLoading: false });
            },
            error => {
                this.setState({ isLoading: false });
                this.props.actions.showAlert({ message: error, variant: "error" });
            }
        );
    };




    GetOpenAccount = () => {
        this.GetClientResidentAccounts(false);
    }
    GetClosedAccount = () => {
        this.GetClientResidentAccounts(true);
    }

    openGridTransactionDetail = (e, event) => {
        const { auth } = this.props;
        let data = { ClientProgramEnrollmentId: event?.ClientProgramEnrollmentId, ClientId: auth?.user?.ClientId, ClientCommunityFinancialsId: -1 };
        this.setState({ isLoading: true, showTransactionDetail: true });
        apiService.post('GETCLIENTCOMMUNITYFINANCIALACCOUNTDETAILS', data).then(response => {
            if (response?.Success && response?.ClientCommunityFinancialDetails) {
                var Detail = response?.ClientCommunityFinancialDetails;
                this.setState({ transactionDetail: { ...this.state.transactionDetail, transactionDetail: Detail, AccountDescription: event.Description, CurrentBalance: event.Balance } });
            }
            this.setState({ isLoading: false, transactionDetail: { ...this.state.transactionDetail, AccountDescription: event?.Description, CurrentBalance: event?.Balance } });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    };
    handleClosedTransactionDetail = () => {
        this.setState({ showTransactionDetail: false, transactionDetail: {} })
    }

    openTransactionModal = (e, event) => {

        this.setState({ showModal: true, modelDetail: event })
    }
    close = () => {
        this.setState({ showModal: false, modelDetail: {} })
    }

    redirectToRecordPayment = () => {
        const { auth } = this.props
        history.push({
            pathname: `/CommunityFinancial/RecordPayment`,
            state: { auth: auth }
        })
    }

    componentDidMount() {
        const { auth } = this.props;
        const UserDetail = auth.user
        this.GetOpenAccount();
        this.GetClosedAccount();
        history.listen((location, action) => {
            this.setState({ showTransactionDetail: false });
        });
        this.setState({ isCommunity: UserDetail.FinancialsType, isStaffLogin: UserDetail.p_IsStaffLogin })
    }
    render() {
        const { value, classes, showModal, transactionDetail, modelDetail, openAccountDetail, closedAccountDetail, isLoading, showTransactionDetail, isCommunity, isStaffLogin } = this.state;
        return (

            <div className={classes.root}>
                {showTransactionDetail ?
                    <CommunityFinancialTransactionDetail transactionDetail={transactionDetail} isLoading={isLoading} closedTransactionDetail={this.handleClosedTransactionDetail} openTransactionModal={this.openTransactionModal} />
                    :
                    <Fragment>
                        <AppBar position="static">
                            <div className="tabs-wrapper">
                                <Tabs value={value} onChange={this.handleChange}>
                                    <Tab label="Open Accounts" {...a11yProps(0)} />
                                    <Tab label="Closed Accounts" {...a11yProps(1)} />
                                </Tabs>
                                {!isStaffLogin && isCommunity === "C" && <div class="float-dollor" onClick={this.redirectToRecordPayment} ><span className="float-dollor-text" style={{ fontWeight: "bold", cursor: "pointer" }}>Make Payment</span> <i class="fa fa-dollar" style={{ fontWeight: "bold" }} ></i></div>}
                            </div>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <CommunityFinancialGrid DetailsFinancialAccounts={openAccountDetail} oncheckboxchange={this.openGridTransactionDetail} isLoading={isLoading} onShowAlert={this.handleShowAlert} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CommunityFinancialGrid DetailsFinancialAccounts={closedAccountDetail} oncheckboxchange={this.openGridTransactionDetail} isLoading={isLoading} onShowAlert={this.handleShowAlert} />
                        </TabPanel>
                    </Fragment>

                }
                <CommunityFinancialTransactionModal onClose={this.close} transaction={modelDetail} showModal={showModal} showNavigation={false} />
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch)
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CommunityFinancial);

