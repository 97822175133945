import React from 'react';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ValidatorComponent } from 'react-form-validator-core';

class CommonTimePicker extends ValidatorComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPickerOpen: false,
        };
    }

    render() {
        const { isPickerOpen } = this.state
        const { ...rest } = this.props;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div onClick={this.handleInputFocus} >
                    <KeyboardTimePicker
                        autoOk
                        variant="inline"
                        format="HH:mm"
                        {...rest}
                        value={this.props.value || null}
                        onChange={this.handleTimeChange}
                        open={isPickerOpen || ''}
                        onClose={this.handlePickerClose}
                        InputAdornmentProps={{ position: 'start' }}
                        invalidDateMessage={''}
                    />
                </div>
                {this.errorText()}
            </MuiPickersUtilsProvider>
        );
    }

    handleTimeChange = (time) => {
        if (this.props.onChange) {
            this.props.onChange(time, this.props.name);
        }
        this.validate(time);
    }
    handleInputFocus = () => {
        this.setState({ isPickerOpen: true });
    }

    handlePickerClose = () => {
        setTimeout(() => {
            this.setState({ isPickerOpen: false });
        }, 100)
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div className="error">
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default CommonTimePicker;
