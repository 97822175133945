import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import Confirmation from './confirmation';
import { history } from '../../../helpers/history';
import { apiService } from '../../../services/api.service';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import MeridianPaymentForm from '../../../shared/components/ui/meridian-payment-form/meridian_payment-form';
import PaymentSummary from './payment_summary';
import PaymentAmounts from './payment_amounts';
import SavedCards from './saved_cards';


class RecordAmounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            paymentMethod: {
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
            },
            transactionId: "",
            isCheckboxSelected: "",
            enrollmentData: [],
            paymentSummaryData: [],
            enrollmentDataTotal: { CurrentBalance: 0, PaymentBalance: 0 },
            clientPaymentMethods: [],
            paymentCardDetail: {},
            selectedCard: {},
            paymentResponse: [],
            isLoading: false,
            showEntryForm: false,
            isCardExpired: false,
            isCardSelectedInvalid: false,
            isMinimumAmount: false,
            noTransactionId: false 
        };
        this.meridianRef = React.createRef();
    };

    getClientPaymentBalances = () => {
        const { auth } = this.props.location.state;
        const { enrollmentDataTotal } = this.state;
        const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
        this.setState({ isLoading: true });
        apiService.post('GETCLIENTPAYMENTBALANCES', data).then(response => {
            function groupBy(key) {
                return response.ClientPaymentBalances?.reduce((acc, obj) => {
                    const property = obj[key];
                    acc[property] = acc[property] || [];
                    acc[property].push(obj);
                    return acc;
                }, {});
            }
            var grouped = groupBy('ClientProgramEnrollmentId');
            var data = [];
            const handleBalance = (amout) => {
                if (amout < 0) return 0;
                return amout

            }

            for (var key in grouped) {
                const singleGroup = grouped[key];
                const firstElement = singleGroup[0];
                const serviceGroupFiltered = singleGroup.filter(s => !!s.Service);
                data.push({
                    ClientProgramEnrollmentId: firstElement.ClientProgramEnrollmentId,
                    Description: firstElement.Description,
                    CurrentBalance: firstElement.Balance,
                    PaymentBalance: serviceGroupFiltered.length > 0 ? 0 : handleBalance(firstElement.Balance),
                    Services: serviceGroupFiltered.map(item => {
                        enrollmentDataTotal.CurrentBalance += item.Balance;
                        enrollmentDataTotal.PaymentBalance += handleBalance(item.Balance);
                        return {
                            Service: item.Service,
                            CurrentBalance: item.Balance,
                            PaymentBalance: handleBalance(item.Balance),
                            ClientProgramServiceId: item.ClientProgramServiceId
                        };
                    })
                });
                if (serviceGroupFiltered.length === 0) {
                    enrollmentDataTotal.CurrentBalance += firstElement.Balance;
                    enrollmentDataTotal.PaymentBalance += handleBalance(firstElement.Balance);
                }
            }
            this.setState({ enrollmentData: data, isLoading: false })
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
            this.setState({ isLoading: false });
        });
    };

    getPaymentMethods = () => {
        const { auth } = this.props.location.state;
        const data = { ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }
        apiService.post('GETCLIENTPAYMENTMETHODS', data).then(response => {
            response.ClientPaymentMethods === null ? this.setState({ showEntryForm: true }) : this.setState({ showEntryForm: false });
            this.setState({ clientPaymentMethods: response.ClientPaymentMethods })
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    handleNext = () => {
        const { enrollmentData, activeStep, paymentMethod, paymentCardDetail, isCardExpired, selectedCard, paymentSummaryData, isCheckboxSelected, enrollmentDataTotal, showEntryForm } = this.state;
        const { auth } = this.props.location.state;
        if (activeStep === 0) {
            if (enrollmentDataTotal.PaymentBalance < 5) {
                this.props.actions.showAlert({ message: 'Please enter a payment of at least $5', variant: 'error', vertical: "top", horizontal: "right" });
                return;
            }
            const paymentSummaryData = enrollmentData.filter(item => {
                var filterServiceData = item.Services.filter(x => x.PaymentBalance !== 0);
                if (item.PaymentBalance !== 0 || filterServiceData.length > 0) {
                    item.Services = filterServiceData;
                    return { ...item }
                }
                return null;
            });
            this.setState({ paymentSummaryData, activeStep: activeStep + 1 });
            this.getPaymentMethods();
        }
        else if (activeStep === 1 && !showEntryForm && !isCardExpired) {
            if (!selectedCard?.CustomerVaultId) {
                this.setState({ isCardSelectedInvalid: true });
                return;
            }
            this.setState({ activeStep: activeStep + 1 });
        }

        else if (activeStep === 2) {
            var transactionDetail = [];
            this.setState({ isLoading: true })

            paymentSummaryData.map(enrData => {
                if (enrData.Services.length > 0) {
                    enrData.Services.map(serData => {
                        transactionDetail.push({ ClientProgramEnrollmentId: enrData.ClientProgramEnrollmentId, ClientProgramServiceId: serData.ClientProgramServiceId, TransactionAmount: serData.PaymentBalance });
                        return null;
                    });
                } else {
                    transactionDetail.push({ ClientProgramEnrollmentId: enrData.ClientProgramEnrollmentId, ClientProgramServiceId: null, TransactionAmount: enrData.PaymentBalance });
                }
                return null;
            })
            const makePaymentData = {
                ClientId: auth.user.ClientId,
                ...paymentMethod,
                clientName: auth.user.FirstName + ' ' + auth.user.LastName,
                ccnumber: paymentCardDetail?.card?.number?.substr(-4) || selectedCard?.CardNumber,
                ccexp: paymentCardDetail?.card?.exp || selectedCard?.Ccexp,
                cctype: paymentCardDetail?.card?.type || selectedCard?.CardType,
                payment_token: paymentCardDetail?.token,
                CompanyId: auth.user.CompanyId,
                amount: this.state.enrollmentDataTotal.PaymentBalance,
                payment: "creditcard",
                type: "sale",
                customer_vault: isCheckboxSelected ? "add_customer" : "",
                customer_vault_id: selectedCard?.CustomerVaultId || "",
                transactionDetails: JSON.stringify(transactionDetail)
            };
            apiService
                .post('MAKEPAYMENT', makePaymentData)
                .then((response) => {
                    if (response) {
                        if(response.transactionid === 0){
                            this.setState({ noTransactionId:  true });   
                        }
                        this.setState({
                            paymentMethod: {
                                firstName: '', lastName: '', city: '', state: '', zip: '', address: ''
                            },
                            transactionId: response.transactionid, paymentResponse: response, isLoading: false, activeStep: this.state.activeStep + 1
                        });
                    }
                },
                    (error) => {
                        this.props.actions.showAlert({ message: error, variant: 'error' });
                    },
                ).finally(() => {
                    if (this.state.paymentResponse.length === 0) {
                        setTimeout(() => {
                            this.setState({ isLoading: false, activeStep: this.state.activeStep + 1 })
                        }, 25000)
                    }
                })
        }
        else if (this.state.activeStep === 3) {
            history.push('/CommunityFinancial')
        }
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: this.state.activeStep - 1 });
        if (activeStep === 0) {
            history.push('/CommunityFinancial')
        }
        if (activeStep === 1) {
            this.setState({ showEntryForm: false })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.checkPaymentFieldsValid()) {
            this.setState({ isLoading: true });
            window.CollectJS.startPaymentRequest();
        }
    }

    checkPaymentFieldsValid = () => {
        if (this.state.showEntryForm) {
            var result = this.meridianRef.current.checkMeridianFormValid();
            return result;
        }
    };


    handleUpdateBalance = (e, type) => {
        const dataEnrollmentIndex = e.target.getAttribute("data-enrollmentIndex");
        const { enrollmentData, enrollmentDataTotal } = this.state;
        const currentInputValue = parseFloat(e.target.value === "" ? 0 : e.target.value);
        let previousInputValue = 0;
        if (type === "ServicePaymentBalance") {
            const dataServiceIndex = e.target.getAttribute("data-serviceIndex");
            previousInputValue = enrollmentData[dataEnrollmentIndex].Services[dataServiceIndex].PaymentBalance;
            enrollmentData[dataEnrollmentIndex].Services[dataServiceIndex].PaymentBalance = currentInputValue;
            // enrollmentData[dataEnrollmentIndex].PaymentBalance += currentInputValue - previousInputValue;
        }
        else {
            previousInputValue = enrollmentData[dataEnrollmentIndex].PaymentBalance;
            enrollmentData[dataEnrollmentIndex].PaymentBalance = currentInputValue;
        }
        enrollmentDataTotal.PaymentBalance += currentInputValue - previousInputValue;
        this.setState({ enrollmentData, enrollmentDataTotal });

        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    };


    handleChange = (e, type) => {
        const { paymentMethod } = this.state;
        paymentMethod[e.target.name] = e.target.value;
        this.setState({ paymentMethod });
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
        if (e.target.type === "checkbox") {
            this.setState({ isCheckboxSelected: e.target.checked });
            return
        }
        else if (type === "savedCard") {
            const { clientPaymentMethods } = this.state
            const selectedCardDetial = clientPaymentMethods.find(item => e.target.value === item.CustomerVaultId.toString());
            this.setState({ selectedCard: selectedCardDetial, isCardSelectedInvalid: false })
            if (selectedCardDetial) {
                this.setState({
                    paymentMethod: {
                        firstName: '', lastName: '', city: '', state: '', zip: '', address: ''
                    }
                })
            }

            // Validate expired card
            const month = new Date().getMonth() + 1
            const currentMonth = month.toString()
            const cardMonth = Number(selectedCardDetial.CcExp.slice(0, 2))
            const currentYear = new Date().getFullYear().toString().slice(-2)
            const cardYear = selectedCardDetial.CcExp.substr(-2)
            cardYear < currentYear ? this.setState({ isCardExpired: true }) : this.setState({ isCardExpired: false })
            if (cardYear === currentYear) {
                cardMonth < currentMonth ? this.setState({ isCardExpired: true }) : this.setState({ isCardExpired: false })
            }

        }
        else if (e.target.name === "state") {
            this.meridianRef.current.markStateValid();
        }
    };


    meridianPaymentCall = (callbackResponse) => {
        this.setState({ activeStep: this.state.activeStep + 1, paymentCardDetail: callbackResponse, isLoading: false })
    }

    toggleCard = () => {
        this.setState({ showEntryForm: !this.state.showEntryForm, isCardSelectedInvalid: false, selectedCard: {} })
    }

    componentDidMount() {
        this.getClientPaymentBalances();
    };

    getSteps = () => {
        return ['Payment Amount', 'Payment Method', 'Summary', 'Confirmation'];
    };

    getStepContent = step => {
        const { enrollmentData, clientPaymentMethods, showEntryForm, paymentMethod, selectedCard, paymentCardDetail, isCardSelectedInvalid, enrollmentDataTotal, isCardExpired, paymentSummaryData, noTransactionId } = this.state
        switch (step) {
            case 0:
                return (
                    <PaymentAmounts handleUpdateBalance={this.handleUpdateBalance} enrollmentData={enrollmentData} enrollmentDataTotal={enrollmentDataTotal} />
                );
            case 1:
                return (
                    <div className='payment-amount-main-container'>
                        {(!showEntryForm) ?
                            <SavedCards clientPaymentMethods={clientPaymentMethods} isCardExpired={isCardExpired} isCardSelectedInvalid={isCardSelectedInvalid} selectedCard={selectedCard} useDifferentCard={this.toggleCard} handleChange={this.handleChange} />
                            :
                            <div className='entry-form'>
                                <div className='another-card' >{clientPaymentMethods && <div style={{ color: " #004cff", cursor: "pointer" }} onClick={this.toggleCard}  >Use a Saved card</div>}</div>
                                <MeridianPaymentForm ref={this.meridianRef} handleChange={this.handleChange} handleSubmit={this.handleSubmit} meridianPaymentMethod={paymentMethod} meridianPaymentCall={this.meridianPaymentCall} handleLoding={this.handleLoding} ></MeridianPaymentForm>
                                <div className='entry-form-checkbox' >
                                    <Checkbox onChange={this.handleChange} ></Checkbox>
                                    <span>Save this card for future use</span>
                                </div>
                            </div>
                        }
                    </div >
                );
            case 2:
                return (
                    <PaymentSummary paymentSummaryData={paymentSummaryData} enrollmentDataTotal={enrollmentDataTotal} selectedCard={selectedCard} paymentCardDetail={paymentCardDetail} />
                );
            case 3:
                const { paymentResponse } = this.state
                return (
                    <Confirmation enrollmentDataTotal={enrollmentDataTotal} paymentResponse={paymentResponse} noTransactionId={noTransactionId} />
                );
            default:
                return null;

        }
    }

    handleLoding = (loading) => {
        this.setState({ isLoading: loading });
    }

    render() {
        const { activeStep, showEntryForm, paymentResponse, isLoading, enrollmentDataTotal } = this.state;
        const showSubmitButton = showEntryForm && activeStep === 1;
        const steps = this.getSteps();
        return (
            <div className="Stepper_form Payment_form">
                {isLoading &&
                    <div className="loaderDiv">
                        <div className="loader">
                            <CircularProgress />
                        </div>
                    </div>
                }
                <Stepper activeStep={activeStep} orientation="vertical" className="stepperForm">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography component={'span'}>{this.getStepContent(index)}</Typography>
                                <div className="agendaBtn">
                                    <div>
                                        {(!paymentResponse.Success) && <Button variant="contained" color="primary" onClick={this.handleBack}>Back</Button>}
                                        {<Button variant="contained" color="primary" disabled={enrollmentDataTotal?.CurrentBalance === 0 ? true : false} type={`${showSubmitButton ? "submit" : "button"}`} form={`${showSubmitButton && "nameform"}`}
                                            onClick={showSubmitButton ? this.checkPaymentFieldsValid : this.handleNext}>{activeStep === 3 ? "Finish" : activeStep === 2 ? "Submit payment" : "Next"}</Button>}
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div >
        )
    }
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordAmounts)