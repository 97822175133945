import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { apiService } from '../../../../services/api.service';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import MeridianPaymentForm from '../meridian-payment-form/meridian_payment-form';

class PaymentMethodModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: {
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
            },
            isSubmitting: false,
            isLoading: false,
            paymentCardDetail: {},
            states: [],
            token: [],

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.meridianRef = React.createRef();
    }


    meridianPaymentCall = (callbackResponse) => {
        const paymentMethod = this.state.paymentMethod;
        const { auth } = this.props;
        const data = {
            ClientId: auth.user.ClientId,
            ...paymentMethod,
            customer_vault: "add_customer",
            cardnumber: callbackResponse?.card.number.substr(-4),
            ccexp: callbackResponse?.card.exp,
            cardtype: callbackResponse?.card.type,
            payment_token: callbackResponse?.token,
            CompanyId: auth.user.CompanyId,
        };
        apiService
            .post('ADDPAYMENTMETHOD', data)
            .then(
                (response) => {
                    this.props.onClose();
                    this.setState({
                        paymentMethod: {
                            firstName: '',
                            lastName: '',
                            city: '',
                            state: '',
                            zip: '',
                            address: '',
                        },
                    });
                    if (response.Success) {
                        this.props.actions.showAlert({ message: 'Card added successfully', variant: 'success', });
                    } else { this.props.actions.showAlert({ message: response.Message, variant: 'error' }); }
                    this.props.GetPaymentMethods();
                },
            )
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        if (this.checkPaymentFieldsValid()) {
            this.setState({ isLoading: true });
            window.CollectJS.startPaymentRequest();
        }
    }


    handleChange = (e) => {
        const { paymentMethod } = this.state;
        paymentMethod[e.target.name] = e.target.value;
        this.setState({ paymentMethod });

        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
        else if (e.target.name === "state") {
            this.meridianRef.current.markStateValid();
        }

    };
    checkPaymentFieldsValid = () => {
        var result = this.meridianRef.current.checkMeridianFormValid();
        return result;
    };

    closeModal = () => {
        this.props.onClose();
        this.setState({
            paymentMethod: {
                firstName: '',
                lastName: '',
                city: '',
                state: '',
                zip: '',
            },
        });
    };
    handleLoding = (loading) => {
        this.setState({ isLoading: loading });
    }

    render() {
        const {
            paymentMethod,
            isLoading,

        } = this.state;

        return (
            <React.Fragment>
                <Modal
                    show={this.props.showModal}
                    onHide={this.closeModal}
                    onEnter={this.initForm}
                    backdrop="static"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {' '}
                            Add Payment Method
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: '100%' }}>
                        {isLoading && (
                            <div className="loaderDiv">
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            </div>
                        )}

                        <MeridianPaymentForm ref={this.meridianRef} meridianPaymentCall={this.meridianPaymentCall} handleChange={this.handleChange} handleSubmit={this.handleSubmit} meridianPaymentMethod={paymentMethod} handleLoding={this.handleLoding} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Cancel
                        </Button>

                        <button
                            type="submit"
                            form="nameform"
                            value="Submit"
                            class="btn btn-primary"
                            onClick={this.checkPaymentFieldsValid}
                        >
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);
