import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';

class SavedCards extends React.Component {
    render() {
        const { isCardSelectedInvalid, clientPaymentMethods, useDifferentCard, handleChange, selectedCard, isCardExpired } = this.props
        return (
            <>
                <div className='select-wrapper' >
                    <NativeSelect onChange={(e) => handleChange(e, "savedCard")} className='card-select' value={selectedCard?.CustomerVaultId}  >
                        <option>Select your card</option>
                        {clientPaymentMethods?.map((item, index) => {
                            return (
                                <option key={index} value={item.CustomerVaultId}> {`${item.CardType?.toUpperCase()}`} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {`**** **** **** ${item?.CardNumber}`}</option>
                            )
                        })}
                    </NativeSelect>

                    <span style={{ color: " #004cff", cursor: "pointer", marginLeft: "30px" }} className='another-card' onClick={useDifferentCard}  >Use a different card</span>
                </div>
                {isCardSelectedInvalid && <span className="error">Select card is required</span>}
                {isCardExpired && <span className='error' >This card is expired please select another card.</span>}
            </>
        )
    }

}



export default SavedCards;
