import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import MessagesBody from './messagesbody';
import MessageSidebar from './messagesidebar';
import NoMessage from './nomessage';
import { apiService } from "../../services/api.service";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kioskMessagingGroupsList: [],
            loadingGroupList: true,
            kioskMessagesList: [],
            loadingMessagesList: false,
            activeGroupDetail: {},
            message: ""
        }
    }
    componentDidMount() {
        this.getKioskMessagingGroups();
    }

    getKioskMessagingGroups = () => {
        const { auth } = this.props;
        const messageGroupId = localStorage.getItem("MessageGroupId")
        const headers = {
            'MessageGroupId': messageGroupId
        };
        apiService.post("GETKIOSKMESSAGINGGROUPS", { CoreTomMessagingGroupIds : messageGroupId ,  KioskMessagingGroupId: -1, ClientId: auth.user.ClientId, CompanyId: auth.user.CompanyId }, { headers: headers })
            .then(
                (response) => {
                    if (response.Success && response.kioskMessagingGroupsList) {
                        this.setState({ kioskMessagingGroupsList: response.kioskMessagingGroupsList });
                    }
                    this.setState({ loadingGroupList: false });
                },
                (error) => {
                    this.props.actions.showAlert({ message: error, variant: "error" });
                    this.setState({ loadingGroupList: false });
                }
            );
    };

    getKioskMessages = () => {
        const { auth } = this.props;
        const { activeGroupDetail } = this.state;
        this.setState({ loadingMessagesList: true, kioskMessagesList: [] });
        apiService.post("GETKIOSKMESSAGES", { KioskMessagingGroupId: activeGroupDetail.KioskMessagingGroupId, ClientId: auth.user.ClientId, OffSetStartDateTime: "", OffSetEndDateTime: "" })
            .then(
                (response) => {
                    if (response.Success && response.kioskMessagesList) {
                        this.setState({ kioskMessagesList: response.kioskMessagesList });
                    }
                    this.setState({ loadingMessagesList: false });
                },
                (error) => {
                    this.props.actions.showAlert({ message: error, variant: "error" });
                    this.setState({ loadingMessagesList: false });
                }
            );
    }

    changeGroupHandler = (activeGroupDetail, index) => {
        let { kioskMessagingGroupsList } = this.state;
        kioskMessagingGroupsList[index].NewMessageRecevied = false;
        this.setState({ activeGroupDetail, kioskMessagingGroupsList }, () => this.getKioskMessages());
    }

    sentMessageHandler = () => {
        const { auth } = this.props;
        const { activeGroupDetail, message, kioskMessagesList } = this.state;
        if (message) {
            apiService.post("INSERTKIOSKMESSAGINGCLIENT", { KioskMessagingGroupId: activeGroupDetail.KioskMessagingGroupId, ClientId: auth.user.ClientId, Message: message, CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss") })
                .then(
                    (response) => {
                        if (response.Success && response.kioskMessagesList) {
                            kioskMessagesList.push(response.kioskMessagesList[0]);
                            this.setState({ message: "", kioskMessagesList });
                        }
                    },
                    (error) => {
                        this.props.actions.showAlert({ message: error, variant: "error" });
                    }
                );
        }
    }

    handleChange = (e) => {
        let message = e.target.value;
        this.setState({ message });
    }

    render() {
        const { loadingGroupList, kioskMessagingGroupsList, activeGroupDetail, kioskMessagesList, loadingMessagesList, message } = this.state;
        return (
            <div className="messaging-module">
                <section id="content">
                    <div className="messages">
                        <MessageSidebar kioskMessagingGroupsList={kioskMessagingGroupsList} loadingGroupList={loadingGroupList} changeGroupHandler={this.changeGroupHandler} activeGroupDetail={activeGroupDetail} />
                        <div className="m-body">
                            <header className="mb-header">
                                {activeGroupDetail.FriendlyName && <div className="mbh-user clearfix">
                                    <Avatar alt={activeGroupDetail.FriendlyName} src="/static/images/avatar/1.jpg" />
                                    <div className="active-group-name">{activeGroupDetail.FriendlyName}</div>
                                </div>}
                            </header>
                            <div className="mb-list">
                                {activeGroupDetail.FriendlyName && <>
                                    <MessagesBody kioskMessagesList={kioskMessagesList} loadingMessagesList={loadingMessagesList} />
                                    <div className="mbl-compose">
                                        <textarea placeholder={`${activeGroupDetail.Active === "N" ? "Group is inactive messaging not allowed." : "Type a message..."}`} name="message" disabled={activeGroupDetail.Active === "N"} value={message} onChange={this.handleChange}></textarea>
                                        <button type="button" onClick={() => this.sentMessageHandler()}><i className="fa fa-paper-plane" disabled={activeGroupDetail.Active === "N"}></i></button>
                                    </div>
                                </>}
                                {!activeGroupDetail.FriendlyName && <NoMessage message="Please select a group to start a conversation." />}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            showAlert: bindActionCreators(actions.showAlert, dispatch),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);